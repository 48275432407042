import { Fragment } from "react";
import { Table } from "reactstrap";

const ContactInformation = () => {
    return (
        <Fragment>
            <Table borderless className="mb-0 mx-5 w-50 h-75">
                <thead>
                    <tr>
                        <th>Algemeen</th>
                        <th>Afdeling Planning</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><i className="lnr-apartment"> </i>info@clooser.nl</td>
                        <td><i className="lnr-apartment"> </i>planning@clooser.nl</td>
                    </tr>
                    <tr>
                        <td><i className="lnr-phone-handset"> </i>077-467 0005</td>
                        <td><i className="lnr-phone-handset"> </i>085 - 273 6079</td>
                    </tr>
                </tbody>
                <thead>
                    <tr>
                        <th className="pt-5">Afdeling Support</th>
                        <th className="pt-5">Afdeling Recruitement</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><i className="lnr-apartment"> </i>support@clooser.nl</td>
                        <td><i className="lnr-apartment"> </i>vacature@clooser.nl</td>
                    </tr>
                    <tr>
                        <td><i className="lnr-phone-handset"> </i>077-467 0005</td>
                        <td><i className="lnr-phone-handset"> </i>077-467 0005</td>
                    </tr>
                </tbody>
                
            </Table>
        </Fragment>
    )
}

export default ContactInformation;