import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";
import PageTitle from "Layout/AppMain/PageTitle";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import axiosInstance from "Api";
import UsersTable from "./RecordsTable"
import {format} from 'date-fns';
import {useParams} from "react-router-dom";
import PageHeading from "Layout/AppMain/PageHeading";

const Default = (props) => {
    const [userdata, setUserData] = useState([]);
    const params = useParams();
    useEffect(() => {
        let requestData = [];
        if (userdata.length === 0) {

            let substitute = params.substitute ? params.substitute : '';

            axiosInstance.get('get-sub-requests?iAdminId=' + substitute).then((response) => {
                if (response.status == 200) {
                    if (response.data.data != null && response.data.data != undefined) {
                        requestData = response.data.data;
                        requestData.forEach((element, index) => {
                            element.FirstDate = format(new Date(element.FirstDate), 'dd-MM-yyyy');
                            element.LastDate = format(new Date(element.LastDate), 'dd-MM-yyyy');
                            //element.LockedBy  = element.lockedBy != undefined ?  "CUSTOM ICON" : element.ID;  
                            // element[index]['Report Time']= format(new Date(element.Melddatum + element.Meldtijd), 'dd-MM-yyyy kk:mm');
                        });
                        setUserData(requestData);
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }, [userdata]);

    return (
        <>
            <Fragment>
            <PageHeading/>
                <TransitionGroup>
                    <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                   timeout={1500} enter={false} exit={false}>
                        <div>
                            <Row>
                                <Col md="12">
                                    <Card className="main-card mb-3">
                                        <CardBody className="p-3">
                                            {(userdata.length > 0) ? <UsersTable data={userdata}/> : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>


        </>
    )
}

export default Default;