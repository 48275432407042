import { Table, NavLink, Nav, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from "reactstrap";
import moment from "moment";
import React, { useEffect, useState } from "react";
import UserInfoModal from "Components/Modal/UserInfoModal/UserInfoModal";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import classNames from "classnames";
import Tippy from "@tippyjs/react";
import { ReactComponent as RectanglePlus } from "../../../../../assets/utils/images/svgs/RectanglePlus.svg";
import { ReactComponent as Football } from "../../../../../assets/utils/images/svgs/Coorfootball.svg";
import { ReactComponent as Calendar } from "../../../../../assets/utils/images/svgs/Calendar.svg";
import { ReactComponent as BlueDelete } from "../../../../../assets/utils/images/svgs/bluedelete.svg";
import { ReactComponent as Thumbsup } from "../../../../../assets/utils/images/svgs/thumbsup.svg";
import { data, event } from "jquery";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import axiosInstance from "Api";
import TextLimit from "Api/TextLimit";

const RequestList = (props) => {

    useEffect(() => {
        moment.locale('fr');
    }, []);

    const params = useParams();
    const substitutionId = params.id || '';

    const iconSwitch = (param) => {
        switch (param) {
            case 'Ingepland':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"18px"} /></span></Tippy>;
            case 'Aangevraagd':
                return <Tippy content={param} placement="bottom"><span><BsQuestionCircle color='#F2983E' font-size={"18px"} /></span></Tippy>;
            case 'Geannuleerd':
                return <Tippy content={param} placement="bottom"><span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /></span></Tippy>;
            default:
                return <Tippy content={'Openstaand'} placement="bottom"><span>----</span></Tippy>;
        }
    }

    const updateShiftdayType = (iSubsDateId, iDayTypeId) => {
        let formData = new FormData();
        formData.append('iSubstitutionId', substitutionId);
        formData.append('iSubsDateId', iSubsDateId);
        formData.append('iDayTypeId', iDayTypeId);

        axiosInstance
            .post('/update/shift-day-type', formData)
            .then((response) => {
                if (response.data.status === true) {
                    toast["success"]("ploegendaggedeelte succesvol bijgewerkt")
                }
            })
            .catch((error) => console.log("catch error:", error));
    }

    if (!props.list) return null;
    return (
        <Table hover size="sm" className="mb-0 coor-verzoeken-table">
            <thead>
                <tr>
                    <th className="text-lef w-32 table-right-border" colSpan="2">&nbsp;</th>
                    <th colSpan="7" className="table-right-border">Ochtend</th>
                    {/* <th className="w-6">&nbsp;</th> */}
                    <th colSpan="7" className="">Middag</th>
                </tr>
                <tr>
                    <th></th>
                    <th className="text-left table-right-border">Datum</th>


                    <th>Vervanger</th>
                    <th>CT</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Info</th>
                    <th>Status</th>
                    <th className="table-right-border">Actie</th>


                    <th>Vervanger</th>
                    <th>CT</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Info</th>
                    <th>Status</th>
                    <th>Actie</th>
                </tr>
            </thead>
            <tbody>
                {props.list.map((block, i) => {
                    return (
                        <tr >
                            <td class="text-capitalize">
                                {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                            </td>
                            <td className="cursor-pointer table-right-border cursor-pointer">
                                {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", {
                                    year: '2-digit',
                                    month: '2-digit',
                                    day: '2-digit'
                                })}
                            </td>
                            {
                                block.data.Ochtend ?
                                    <>
                                        <td>
                                            {
                                                block.data.Ochtend.Name !== '' && block.data.Ochtend.Name !== null ? 
                                                window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(block.data.Ochtend.vFirstName, block.data.Ochtend.vMiddleName, block.data.Ochtend.vLastName)
                                                        :TextLimit.AfwezigeLimitOptionTwo(block.data.Ochtend.vFirstName, block.data.Ochtend.vMiddleName, block.data.Ochtend.vLastName) : '----'
                                            }          
                                        </td>
                                        <td>{block.data.Ochtend.Name !== '' && block.data.Ochtend.ct !== '' && block.data.Ochtend.ct !== null ? block.data.Ochtend.ct : '----'}</td>
                                        <td>
                                            {block.data.Ochtend.iSubstitutorAdminId != 0 ?
                                                <select onChange={(e) => updateShiftdayType(block.data.Ochtend.iSubsDateId, e.target.value)}>
                                                    <option value="0">&nbsp;</option>
                                                    {props.dayTypeList.map((key) => {
                                                        return ( 
                                                                <option value={key.id} selected={key.id == block.data.Ochtend.iDayTypeId ? true : false}>{key.abbr}</option>
                                                            )
                                                        })
                                                    }
                                                </select> : block.data.Ochtend.Name != '' ? block.data.Ochtend.ctDefaultValue : '----'
                                            }
                                            
                                        </td>
                                        <td>{block.data.Ochtend.Name !== '' && block.data.Ochtend.wtfValue !== null ? block.data.Ochtend.wtfValue : '----'}
                                            {
                                                block.data.Ochtend.Name !== '' &&
                                                <select onChange={(e) => props.updateWtf(block.data.Ochtend.iSubsDateId, e.target.value)}>
                                                    <option value="?" selected={block.data.Ochtend.wtf == '?' ? true : false}>?</option>
                                                    <option value="K" selected={block.data.Ochtend.wtf == 'K' ? true : false}>K</option>
                                                    <option value="L" selected={block.data.Ochtend.wtf == 'L' ? true : false}>L</option>
                                                    <option value="P" selected={block.data.Ochtend.wtf == 'P' ? true : false}>P</option>
                                                    <option value="H" selected={block.data.Ochtend.wtf == 'H' ? true : false}>H</option>
                                                </select>
                                            }
                                        </td>
                                        <td className="icon-styling">
                                            {
                                                block.data.Ochtend.Name !== '' ? 
                                                <>
                                                    <span className="me-3"><RectanglePlus /></span>
                                                    <Football />
                                                </>
                                                : '----'
                                            }
                                            
                                        </td>
                                        <td>
                                            {
                                                block.data.Ochtend.iSubstitutorAdminId !== 0 ?
                                                <UncontrolledButtonDropdown >
                                                    <DropdownToggle color="link" className="p-0">

                                                        {iconSwitch(block.data.Ochtend.eSubStatus)}
                                                    </DropdownToggle>
                                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">

                                                        <Nav vertical className="dropdown-custom-link">

                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Ochtend.iSubsDateId, 'Ingepland')}>
                                                                <span><BsCheckCircle color='#39A825' font-size={"18px"} /> </span>
                                                            </NavLink>
                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Ochtend.iSubsDateId, 'Aangevraagd')}>
                                                                <span><BsQuestionCircle color='#F2983E' font-size={"18px"} /> </span>
                                                            </NavLink>
                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Ochtend.iSubsDateId, 'Geannuleerd')}>
                                                                <span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /> </span>
                                                            </NavLink>
                                                        </Nav>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                : '----'
                                            }
                                        </td>
                                        <td className="icon-styling table-right-border">
                                            {
                                                block.data.Ochtend.iSubstitutorAdminId == 0 ?
                                                    <>
                                                        <span className="me-3 svg-pointer"><Calendar /></span>
                                                        <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Ochtend.iSubsDateId)}><BlueDelete /></span>
                                                    </>
                                                    :
                                                    <>
                                                        <span className="me-3 svg-pointer"><Thumbsup /></span>
                                                        <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Ochtend.iSubsDateId)}><BlueDelete /></span>
                                                    </>
                                            }
                                            
                                        </td>
                                    </>
                                    : <td colSpan="7" className="text-center table-right-border"></td>
                            }

                            {
                                block.data.Middag ?
                                    <>
                                        <td>
                                            {
                                                block.data.Middag.Name !== '' && block.data.Middag.Name !== null ? 
                                                (window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(block.data.Middag.vFirstName, block.data.Middag.vMiddleName, block.data.Middag.vLastName)
                                                            :TextLimit.AfwezigeLimitOptionTwo(block.data.Middag.vFirstName, block.data.Middag.vMiddleName, block.data.Middag.vLastName)) : '----'
                                            }          
                                        </td>
                                        <td>{block.data.Middag.Name !== '' && block.data.Middag.ct !== '' && block.data.Middag.ct !== null ? block.data.Middag.ct : '----'}</td>
                                        <td>
                                            {block.data.Middag.iSubstitutorAdminId != 0 ?
                                                <select onChange={(e) => updateShiftdayType(block.data.Middag.iSubsDateId, e.target.value)}>
                                                    <option value="0">&nbsp;</option>
                                                    {props.dayTypeList.map((key) => {
                                                        return ( 
                                                                <option value={key.id} selected={key.id == block.data.Middag.iDayTypeId ? true : false}>{key.abbr}</option>
                                                            )
                                                        })
                                                    }
                                                </select> : block.data.Middag.Name != '' ? block.data.Middag.ctDefaultValue : '----'
                                            }
                                            
                                        </td>
                                        <td>{block.data.Middag.Name !== '' && block.data.Middag.wtfValue !== null ? block.data.Middag.wtfValue : '----'}
                                            {
                                                block.data.Middag.Name !== '' &&
                                                <select onChange={(e) => props.updateWtf(block.data.Middag.iSubsDateId, e.target.value)}>
                                                    <option value="?" selected={block.data.Middag.wtf == '?' ? true : false}>?</option>
                                                    <option value="K" selected={block.data.Middag.wtf == 'K' ? true : false}>K</option>
                                                    <option value="L" selected={block.data.Middag.wtf == 'L' ? true : false}>L</option>
                                                    <option value="P" selected={block.data.Middag.wtf == 'P' ? true : false}>P</option>
                                                    <option value="H" selected={block.data.Middag.wtf == 'H' ? true : false}>H</option>
                                                </select>
                                            }

                                        </td>
                                        <td className="icon-styling">
                                            {
                                                block.data.Middag.Name !== '' ?
                                                <>
                                                    <span className="me-3"><RectanglePlus /></span>
                                                    <Football />
                                                </>
                                                :
                                                '----'
                                            }
                                            
                                        </td>
                                        <td>
                                            {
                                                block.data.Middag.iSubstitutorAdminId !== 0 ?
                                                <UncontrolledButtonDropdown >
                                                    <DropdownToggle color="link" className="p-0">

                                                        {iconSwitch(block.data.Middag.eSubStatus)}
                                                    </DropdownToggle>
                                                    <DropdownMenu end className="rm-pointers dropdown-menu-lg functies-dropdown-width">

                                                        <Nav vertical className="dropdown-custom-link">

                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Middag.iSubsDateId, 'Ingepland')}>
                                                                <span><BsCheckCircle color='#39A825' font-size={"18px"} /> </span>
                                                            </NavLink>
                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Middag.iSubsDateId, 'Aangevraagd')}>
                                                                <span><BsQuestionCircle color='#F2983E' font-size={"18px"} /> </span>
                                                            </NavLink>
                                                            <NavLink className="link-gap" onClick={() => props.changeStatus(block.data.Middag.iSubsDateId, 'Geannuleerd')}>
                                                                <span><TiDeleteOutline color='#FB2E5F' font-size={"24px"} /> </span>
                                                            </NavLink>
                                                        </Nav>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                                : '----'
                                            }
                                        </td>
                                        <td className="icon-styling">
                                            {
                                                block.data.Middag.iSubstitutorAdminId == 0 ?
                                                <>
                                                    <span className="me-3 svg-pointer"><Calendar /></span>
                                                    <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Middag.iSubsDateId)}><BlueDelete /></span>
                                                </>
                                                :
                                                <>
                                                    <span className="me-3 svg-pointer"><Thumbsup /></span>
                                                    <span className="svg-pointer" onClick={() => props.confirmDelete(block.data.Middag.iSubsDateId)}><BlueDelete /></span>
                                                </>
                                            }
                                            
                                        </td>
                                    </>
                                    : <td colSpan="7" className="text-center"></td>
                            }
                        </tr>
                    )
                })
                }
            </tbody>
        </Table>
    );
};

export default RequestList;