import React, {Fragment, useEffect, useState} from "react";
import {Alert, Button, Col, FormGroup, Row} from "reactstrap";
import {useNavigate} from 'react-router-dom';
import AuthService from "Api/Auth";
import {Form, Formik} from 'formik';
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setUserProfilePicture } from "reducers/UserProfile";
import { setUserRole } from 'reducers/UserPermission';
import DefaultButton from "Components/Buttons/DefaultButton";
import axiosInstance from "Api";
import { getCurrentRole } from "Components/Router/Permission";

// Layout
const SignupSchema = Yup.object().shape({
    username: Yup.string()
        .required('Gebruikersnaam is verplicht'),
    password: Yup.string()
        .required('Wachtwoord is verplicht')
});

const LoginBoxed = ({match}) => {

    const dispatch = useDispatch();

    let navigate = useNavigate();
    const [error, setError] = useState(null)

    useEffect(() => {
        if (AuthService.checkUserLoggedIn()) {
            const roles = JSON.parse( localStorage.getItem('USER_ROLES'));
            navigate(getCurrentRole(roles).route);
        }
    });

    const handleSubmit = async (data) => {
        try {
            const result = await AuthService.login(data.username, data.password).catch(e => console.log(e));

            if (result && result.status === 200 && result.data) {
                let response = result.data;
                if (response.error) {
                    setError(response.error)
                } else if (response.roles.length === 0 || response.roles[0] === "") {
                    setError("U heeft niet de juiste rol om in te loggen op dit systeem")
                } else {
                    AuthService.makeAuth(response);
                    AuthService.setUserRoles(response.roles);
                    dispatch(setUserRole(response.roles));

                    dispatch(setUserProfilePicture(response.user.profileImage));
                    navigate(getCurrentRole(response.roles).route);
                }
            } else {
                setError(result.data.error);
            }
        } catch (error) {
            let response = error.response.data;

            if (response.error != undefined && response.error != '') {
                setError(response.error);
            }
        }
    };

    return (
        <Fragment>
            <div className="h-100 bg-heavy-rain custom-footerbar bg-animation">
                <div className="d-flex h-100 justify-content-center align-items-center">
                    <Col md="8" className="mx-auto app-login-box">
                        <div className="app-logo-inverse mx-auto mb-3"/>
                        <div className="modal-dialog w-100 mx-auto">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="h5 modal-title text-center">
                                        <h4 className="mt-2">
                                            <div>Inloggen op clooser.nl</div>
                                        </h4>
                                    </div>
                                    {error ?
                                        <Alert color="danger">
                                            {error}
                                        </Alert>
                                        : null}
                                    <Formik
                                        initialValues={{
                                            username: '',
                                            password: '',
                                        }}
                                        validationSchema={SignupSchema}
                                        onSubmit={async (data) => {
                                            handleSubmit(data);
                                        }}>
                                        {props => (
                                            <Form>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <input id="username" name="username"
                                                                   className="form-control" placeholder="Gebruikersnaam"
                                                                   type="username" onChange={props.handleChange}
                                                                   value={props.values.username}/>
                                                            {props.errors.username && props.touched.username ? (<div
                                                                style={{color: 'red'}}>{props.errors.username}</div>) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md={12}>
                                                        <FormGroup>
                                                            <input id="password" name="password"
                                                                   className="form-control" placeholder="Wachtwoord"
                                                                   type="password" onChange={props.handleChange}
                                                                   value={props.values.password}/>
                                                            {props.errors.password && props.touched.password ? (<div
                                                                style={{color: 'red'}}>{props.errors.password}</div>) : null}
                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                                <div className="modal-footer clearfix">
                                                    <div className="float-start">
                                                        <a href="#" onClick={(e) => e.preventDefault()}
                                                           className="btn-lg btn btn-link btn-color-style">
                                                            Wachtwoord vergeten?
                                                        </a>
                                                    </div>
                                                    <div className="float-end">
                                                        <DefaultButton color="primary" size="lg" type="submit" className="btn-color-style">
                                                            Inloggen
                                                        </DefaultButton>
                                                    </div>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>

                                </div>
                            </div>
                        </div>
                    </Col>
                </div>
            </div>
        </Fragment>
    )
}

export default LoginBoxed;
