import React, {Fragment} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody, Col, Row,} from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import { default as AvailabilityTable } from "Components/Availability";

const Availability = () => {

    return (
        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                                timeout={1500} enter={false} exit={false}>
                    <div>
                        <PageHeading />

                        <Row>
                            <Col md="12" lg="12">
                                <AvailabilityTable />
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Availability;
