import { Fragment } from "react";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Card, CardBody, CardGroup } from "reactstrap";
import PageHeading from "Layout/AppMain/PageHeading";
import ContactInformation from "../Components/ContactInformation";
import Video from "Components/Video/Video";

const ContactDetails = () => {
    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <Card>
                        <CardBody className="d-flex justify-content-between align-items-center">
                            <ContactInformation />
                            <Video link="https://www.youtube.com/embed/Loz98I_44oQ" title="YouTube video" aspect="16x9" w="50" />
                        </CardBody>
                    </Card>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default ContactDetails;