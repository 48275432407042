import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import axiosInstance from "Api";
import PageHeading from "Layout/AppMain/PageHeading";
import CustomFilter from "Components/ReactTable/Filters/CustomFilters";
import ReactTableGeneric from "Components/ReactTableGeneric";
import {Link} from "react-router-dom";
import Tippy from "@tippyjs/react";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {BsCheckCircle} from "react-icons/bs";
import Historie from "../../../../assets/utils/images/svgs/Historie.svg";
import TextLimit from "Api/TextLimit";

const SubstitutionsHistory = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilter] = useState([]);
    const [dropdownData, setDropdownData] = useState([]);

    useEffect(() => {
        if (data.length === 0) {
            axiosInstance.get('requests?filter=historie').then((response) => {
                if (response.data) {
                    setData(response.data);

                    response.data.forEach(element => {
                        element.filterName = element.vSchoolName;
                        element.filterId = element.school_id;
                    });

                    setFilter(response.data);

                    const arrayUniqueByschoolId = [...new Map(response.data.map(item => [item["school_id"], item])).values()];
                    setDropdownData(arrayUniqueByschoolId);
                }

            }).catch(() => console.error);
        }
    }, [data]);


    const handleSelect = (e) => {
        let seletedValue = e;
        let updatedList = seletedValue
            ? filterData.filter((value) => value.school_id == seletedValue)
            : filterData;
        setData(updatedList);
    }

    const iconSwitch = (param) => {
        switch(param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"26px"} className="dot-circle-icon-align" /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><img src={Historie} width="23" /></span></Tippy >;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"}/></span></Tippy>;
            default:
              return '--';
        }
    }

    const columns = useMemo(
        () => [
            {
                header: 'Id',
                cell: (value) => { 
                    return (
                        <Link
                            to={`/verzoeken/${value.row.original.iSubstitutionId}`}
                            key={value.row.original.iSubstitutionId}
                        >
                            {value.row.original.iSubstitutionId}
                        </Link>)
                },
                accessorKey: 'iSubstitutionId',
            },
            {
                header: () => 'Melddatum',
                accessorKey: 'dAddedByDir',
            },
            {
                header: () => 'Organisatie',
                accessorKey: 'vOrgName',
                cell: (value) => { 
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.vOrgName)
                        : TextLimit.limitStringSmallScreen(value.row.original.vOrgName)
                    )
                },
            },
            {
                header: () => 'School',
                accessorKey: 'vSchoolName',
                cell: (value) => { 
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.vSchoolName)
                        : TextLimit.limitStringSmallScreen(value.row.original.vSchoolName)
                    )
                },
            },
            {
                header: () => 'Afwezige',
                accessorKey: 'vFirstName',
                cell: value => {
                    return (window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    :TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                    )
                },
            },
            {
                header: () => '1e dag',
                accessorKey: 'vSubsDate',
            },
            {
                header: () => 'Laatste dag',
                accessorKey: 'vLastDate',
            },
            {
                header: () => 'Code',
                accessorKey: 'vCode',
                cell: (value) => { 
                    return (
                        TextLimit.codeLimitOptionOne(value.row.original.vCode)
                    )
                },
            },
            {
                header: () => 'Status',
                accessorKey: 'eStatus',
                cell: (value) => { 
                    return iconSwitch(value.row.original.eStatus)
                },
            },
        ],
        []
    );

    return (
        <div className="app-main__inner">
            <PageHeading>
                <CustomFilter data={dropdownData} handleCallback={handleSelect}/>
            </PageHeading>
            <Row>
                <Col md="12">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <ReactTableGeneric
                                data={data}
                                columns={columns}
                                tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-left"
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default SubstitutionsHistory;
