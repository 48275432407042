import AuthApi from "../Api/Auth";

const Auth = {
    authenticate(token) {
        localStorage.setItem('REACT_TOKEN_AUTH', JSON.stringify(token));
    },
    getToken() {
        if (localStorage.getItem("REACT_TOKEN_AUTH") !== null) {
            const token = JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH'));
            if (token.access_token) {
                return token.access_token;
            }
            return token;
        }
        return '';
    },
    logout() {
        localStorage.clear();
        return AuthApi.logout();
    },
    isLoggedIn() {
        if (localStorage.getItem("REACT_TOKEN_AUTH") !== null) {
            return JSON.parse(localStorage.getItem('REACT_TOKEN_AUTH'));
        }
        return false;
    },
    setAuthenticatedUser(user) {
        localStorage.setItem('user', JSON.stringify(user));
    },
    getAuthenticatedUser() {
        if (localStorage.getItem("user") !== null) {
            return JSON.parse(localStorage.getItem('user'));
        }
        return null;
    }
};
export default Auth;