import React, {Fragment, useEffect, useState} from "react";
import {Col, FormGroup, Input, Label} from "reactstrap";
import InputWrapper from "Pages/Profile/Forms/InputWrapper";
import {Typeahead} from "react-bootstrap-typeahead";
import Auth from "../../../../../../services/Auth"
import classNames from "classnames";
import axiosInstance from "Api";

const AdditionalInformation = ({ formikProps, formDataState, setFormDataState, isDisabled, codeListData, verlofLink, data, planningId }) => {
    const [perSMS, setPerSMS] = useState(false);
    const { setFieldValue, values, errors } = formikProps;

    function convertEmailsStrToArr(value) {
        const emailArray = value.split(',');
        setFieldValue('informEmailExtra2', emailArray)
    }

    const [userDetails, setUser] = useState({});

    useEffect(() => {
        if (planningId == 0 || planningId == 3) {
            setFieldValue('planning', 'centrale')
        } else if (planningId == 1 || planningId == 4) {
            setFieldValue('planning', 'school')
        } else {
            setFieldValue('planning', '')
        }
    }, [planningId]);

    useEffect(() => {
        axiosInstance.get('me').then(response => {
            if (response.status === 200) {
                setUser(response.data.user);
            }
        }).catch(e => console.log(e))
    }, [])

    

    return (
        <Fragment>
            {verlofLink && codeListData && //check if link and code is selected
            <FormGroup row className={classNames({"text-danger": values.type.length == 0 && isDisabled})}>
                <Label sm={4}>
                    {codeListData.eCategory} *
                </Label>
                <Col sm={4} className="col-form custom-radio-btn-style">
                    <Input
                        type="radio"
                        name="type"
                        value="Verzuim"
                        className="me-2"
                        onChange={e => {
                            setFieldValue('type', 'Verzuim')
                            setFormDataState({ ...formDataState, type: 'Verzuim' })
                            window.open(verlofLink, "_blank", 'height=auto,width=auto')
                        }}
                        defaultChecked={formDataState.type === 'Verzuim'}
                        />{codeListData.eCategory} extern melden
                    {values.type.length == 0 && isDisabled && <div className="text-danger">Verplicht veld</div>}
                </Col>
                <Col sm={4} className="col-form custom-radio-btn-style">
                    <Input
                        type="radio"
                        name="type"
                        value="Overslaan"
                        className="mx-2 me-2"
                        onChange={e => {
                            setFieldValue('type', 'Overslaan')
                            setFormDataState({ ...formDataState, type: 'Overslaan' })
                        }}
                        defaultChecked={formDataState.type === 'Overslaan'}
                        />Overslaan
                </Col>
            </FormGroup>
            }
            {
                (typeof planningId !== 'undefined' && planningId !== 0 && planningId !== 1) &&
                <FormGroup row className={classNames({"text-danger": values.planning.length == 0 && isDisabled})}>
                    <Label sm={4}>Planning</Label>
                    <Col sm={4} className="col-form">
                        <Input
                            type="radio"
                            name="planning"
                            value="school"
                            className="me-2"
                            onChange={e => {
                                setFieldValue('planning', e.target.value)
                                setFormDataState({ ...formDataState, planning: 'school' })
                            }}
                            checked={values.planning === 'school'}
                            />Schoolplanning
                        {values.planning.length == 0 && isDisabled && <div className="text-danger">Verplicht veld</div>}
                    </Col>
                    <Col sm={4} className="col-form">
                        <Input
                            type="radio"
                            name="planning"
                            value="centrale"
                            className=" mx-2 me-2"
                            onChange={e => {
                                setFieldValue('planning', e.target.value)
                                setFormDataState({ ...formDataState, planning: 'centrale' })
                            }}
                            checked={values.planning === 'centrale'}
                        />Centrale Planning
                    </Col>
                </FormGroup>
            }
            <InputWrapper label="Kostenplaats / project">
                <Input
                    type="text"
                    name="label"
                    className="form-control"
                    value={formDataState.label}
                    maxLength="100"
                    onChange={e => {
                        setFieldValue('label', e.target.value)
                        setFormDataState({ ...formDataState, label: e.target.value })
                    }}
                />
            </InputWrapper>
            <FormGroup row>
                <Label sm={4}>Wijze van informeren</Label>
                <Col sm={4} className="col-form custom-radio-btn-style" style={{wordBreak: 'break-all'}}>
                    {userDetails && userDetails?.vBusinessEmail
                        ? <Fragment><Input type="radio" name="informingMethod" className="me-2" defaultChecked={!formDataState.isOpen}
                            onChange={() => {
                                setFormDataState({ ...formDataState, isOpen: false })
                            }}
                        />{userDetails?.vBusinessEmail}</Fragment>
                        : "Uw zakelijk e-mailadres is onbekend"
                    }
                </Col>
                <Col sm={4} className="col-form custom-radio-btn-style">
                    <Input type="radio" name="informingMethod" className=" mx-2 me-2" defaultChecked={formDataState.isOpen}
                        onChange={() => {
                            setFormDataState({ ...formDataState, isOpen: true })
                        }}
                    />Aangepast


                </Col>
            </FormGroup>

            {
                formDataState.isOpen &&
                <Fragment>
                    <FormGroup row>
                        <Label sm={4}>per SMS</Label>
                        <Col sm={8} className="col-form">
                            {userDetails && userDetails?.iMobileNumber
                                ? <Fragment><Input type="checkbox" className="me-2" name="informSms" defaultChecked={typeof values.iHowToInformId == "string" && values.iHowToInformId.includes("3")}
                                    value={userDetails?.iMobileNumber}
                                    onChange={() => {
                                        setPerSMS(!perSMS); 
                                        setFieldValue('iHowToInformId', typeof values.iHowToInformId == "string" && values.iHowToInformId.includes("3") ? values.iHowToInformId.replace("3, ", "") : values.iHowToInformId += "3, ")
                                        setFormDataState({ ...formDataState, iHowToInformId: values.iHowToInformId })
                                }} />{userDetails?.iMobileNumber}</Fragment>
                                : "Uw mobiel nummer is onbekend"
                            }
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={4}>per prive mail</Label>
                        <Col sm={8} className="col-form">
                                {userDetails && userDetails?.vEmail
                                    ? <Fragment><Input type="checkbox" className="me-2" name="informSms" defaultChecked={typeof values.iHowToInformId == "string" && values.iHowToInformId.includes("5")}
                                        value={userDetails?.iMobileNumber}
                                        onChange={() => {
                                            setFieldValue('iHowToInformId', (typeof values.iHowToInformId == "string" && values.iHowToInformId.includes("5")) ? values.iHowToInformId.replace("5, ", "") : values.iHowToInformId += "5, ")
                                            setFormDataState({ ...formDataState, iHowToInformId: values.iHowToInformId })
                                        }} />{userDetails?.vEmail}</Fragment>
                                    : "Uw privé e-mailadres is onbekend"
                                }
                        </Col>
                    </FormGroup>
                    <h4 className="extra-info">Extra gebruiker infomeren</h4>
                    <FormGroup row className="custom-mb">
                        <Label sm={4}>
                            Naam
                        </Label>
                        <Col sm={8}>
                            <Typeahead id="iAdminId" labelKey="name" multiple={true} options={formDataState.informEmailExtra1.length < 2 ? data : []}
                                defaultSelected={formDataState.informEmailExtra1}
                                onChange={users => {
                                    
                                    if (users.length <= 2) {
                                        setFieldValue('informEmailExtra1', users.map(user => user.email).toString())
                                        setFormDataState({ ...formDataState, informEmailExtra1: users.map(user => user.email) })
                                    }
                                    
                                }}
                                placeholder="Selecteer een gebruiker..." name="informEmailExtra1" />
                                <p className="col-form">Selecteer maximaal 2 gebruikers</p>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="custom-mb">
                        <Label sm={4}>
                            E-mailadres
                        </Label>
                        <Col sm={8}>
                            <Input
                                type="text"
                                name="informEmailExtra2"
                                className={classNames({"border border-danger rounded": errors.informEmailExtra2 && isDisabled})}
                                value={formDataState.informEmailExtra2}
                                maxLength="500"
                                onChange={e => {
                                    setFormDataState({ ...formDataState, informEmailExtra2: e.target.value})
                                    convertEmailsStrToArr(e.target.value)
                                }} />
                                {errors.informEmailExtra2 && isDisabled && <div className="text-danger">{errors.informEmailExtra2}</div>}
                                <p className="col-form">Bij meerdere geadresseerden gebruikt u een , als scheidingsteken</p>
                        </Col>
                    </FormGroup>
                </Fragment>
            }

        </Fragment>
    )
};

export default AdditionalInformation;
