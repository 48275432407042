import React, {useState} from 'react';
import {Field, useField} from "formik";
import InputVisibility from "./InputVisibility";
import {Button, Col, FormFeedback, Input, Label} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

function BootstrapInputGroup({field, meta, ...props}) {
    const [isVisible, setIsVisible] = useState(false);
    const [didFocus, setDidFocus] = React.useState(false);
    const handleClickShowPassword = (e) => {
        setIsVisible(!isVisible);
    }
    const handleFocus = () => setDidFocus(true);

    const showFeedback =
        (!!didFocus && (field.value!==undefined && field.value.trim().length > 5)) || meta.touched;
    return (
        <>
            <div className="input-group mb-3">
                <Input type={isVisible ? "text" : "password"}
                       className={`form-control
                        ${showFeedback ? (meta.error ? 'is-invalid' : 'is-valid') : ''
                               }`}
                       {...field}
                       onFocus={handleFocus}
                />
                <div className="input-group-append">
                    <Button color="primary" onClick={handleClickShowPassword}>
                        <FontAwesomeIcon icon={ isVisible ? faEye : faEyeSlash}/>
                    </Button>
                </div>
                {meta.touched && meta.error && (
                    <div className="invalid-feedback">{meta.error}</div>
                )}
                {meta.touched && !meta.error && (
                    <div className="valid-feedback">{meta.error}</div>
                )}

            </div>

        </>

    );
}

export default BootstrapInputGroup;