import { Table } from "reactstrap";
import moment from "moment";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import TextLimit from "Api/TextLimit";

const WeekTable = ({ list, sum}) => {
    // add animation when remove data (row)
    return (
        <Table size="sm" borderless className="mb-0 subs-main-table hidden-row-style vervanger-overview">
            <thead>
                <tr>
                    <th className="text-lef w-32" >&nbsp;</th>
                    <th className="text-lef w-32" >&nbsp;</th>
                    <th className="text-lef w-32" >&nbsp;</th>
                    <th colSpan="7" className="custom-border-left">Ochtend</th>
                    <th className="w-6">&nbsp;</th>
                    <th colSpan="7" className="custom-border-left">Middag</th>
                    <th colSpan="2" className="custom-border-left text-center">Totaal</th>
                </tr>
                <tr>
                    <th className="text-left" >Week</th>
                    <th className="text-left" >Dag</th>
                    <th className="text-left" >Datum</th>

                    <th className="text-left custom-border-left">School</th>
                    <th></th>
                    <th>Bestuur</th>
                    <th>Verzoek</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Uren</th>

                    <th>&nbsp;</th>
                    <th className="text-left custom-border-left">School</th>
                    <th></th>
                    <th>Bestuur</th>
                    <th className="text-left">Verzoek</th>
                    <th>DT</th>
                    <th>WTF</th>
                    <th>Uren</th>
                    <th className="custom-border-left">WTF</th>
                    <th className="float-right">Uren</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(list).map(function (key) {
                    let middagWtfSum = (list[key].middag !== undefined && list[key].middag.wtf !== undefined) ? list[key].middag.wtf : 0;
                    let ochtendWtfSum = (list[key].ochtend !== undefined && list[key].ochtend.wtf !== undefined) ? list[key].ochtend.wtf : 0;
                    let wtf = middagWtfSum + ochtendWtfSum;
                    let middagUrenSum = (list[key].middag !== undefined && list[key].middag.uren !== undefined) ? list[key].middag.uren : 0;
                    let ochtendUrenSum = (list[key].ochtend !== undefined && list[key].ochtend.uren !== undefined) ? list[key].ochtend.uren : 0;
                    let uren = middagUrenSum + ochtendUrenSum;
                    let week = '';
                    if (list[key].ochtend !== undefined) {
                        week = list[key].ochtend !== undefined ? list[key].ochtend.weeknr : '';
                    } else {
                        week = list[key].middag !== undefined ? list[key].middag.weeknr : '';
                    }
                    
                    return (
                        <tr>
                            <td >{week}</td>
                            <td>{new Date(moment(key, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}</td>
                            <td>{key}</td>
                            <td className="custom-border-left">{list[key].ochtend !== undefined ? 
                                (
                                    window.innerWidth > 1250 ?
                                    TextLimit.limitStringLargeScreen(list[key].ochtend.school)
                                    : TextLimit.limitStringSmallScreen(list[key].ochtend.school)
                                )
                                : ''}
                            
                            </td>
                            <td></td>
                            <td>{list[key].ochtend !== undefined ? 
                            (
                                window.innerWidth > 1250 ?
                                TextLimit.limitStringLargeScreen(list[key].ochtend.bestuur)
                                : TextLimit.limitStringSmallScreen(list[key].ochtend.bestuur)
                            ) 
                            
                            : ''}</td>
                            <td>
                                {
                                    list[key].ochtend !== undefined ? (
                                    <Link
                                        to={`/home/${list[key].ochtend.verzoek}`}
                                        key={'id'}
                                    >
                                        {list[key].ochtend !== undefined ? list[key].ochtend.verzoek : ''}
                                    </Link>
                                    ) : ''
                                }
                            </td>
                            <td>{list[key].ochtend !== undefined ? list[key].ochtend.dt : ''}</td>
                            <td>{list[key].ochtend !== undefined ? list[key].ochtend.wtf.toFixed(4) : ''}</td>
                            <td>{list[key].ochtend !== undefined ? list[key].ochtend.uren.toFixed(4) : ''}</td>


                            <td></td>
                            <td className="custom-border-left">{list[key].middag !== undefined ? 
                                (
                                    window.innerWidth > 1250 ?
                                    TextLimit.limitStringLargeScreen(list[key].middag.school)
                                    : TextLimit.limitStringSmallScreen(list[key].middag.school)
                                )
                                 
                                : ''}</td>
                            <td></td>
                            <td>{list[key].middag !== undefined ? 
                            (
                                window.innerWidth > 1250 ?
                                TextLimit.limitStringLargeScreen(list[key].middag.bestuur)
                                : TextLimit.limitStringSmallScreen(list[key].middag.bestuur) 
                            )
                            
                            : ''}</td>
                            <td>
                                {
                                    list[key].middag !== undefined ? (
                                    <Link
                                        to={`/home/${list[key].middag.verzoek}`}
                                        key={'id'}
                                    >
                                        {list[key].middag !== undefined ? list[key].middag.verzoek : ''}
                                    </Link>
                                    ) : ''
                                }
                                
                            </td>
                            <td>{list[key].middag !== undefined ? list[key].middag.dt : ''}</td>
                            <td>{list[key].middag !== undefined ? list[key].middag.wtf.toFixed(4) : ''}</td>
                            <td>{list[key].middag !== undefined ? list[key].middag.uren.toFixed(4) : ''}</td>
                            <td className="custom-border-left float-left text-right">{wtf.toFixed(4)}</td>
                            <td className="text-right">{uren.toFixed(4)}</td>
                        </tr>
                    )
                })}
                <tr className="week-sum-border">
                    <td className="week-sum-border-none"></td>
                    <td className="week-sum-border-none"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className=" float-left text-right">{sum.wtf !== undefined ? sum.wtf.toFixed(4) : ''}</td>
                    <td className="text-right">{sum.uren !== undefined ? sum.uren.toFixed(4) : ''}</td>
                </tr>
            </tbody>
        </Table>

    );
};

export default WeekTable;