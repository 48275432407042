import { Table } from "reactstrap";
import moment from "moment";
import React, { useEffect } from "react";

const MaandoverzichtTable = ({ arrMonth }) => {
    // console.log(data);
    // add animation when remove data (row)

    return (
        <Table hover size="sm" borderless className="mb-0 maandoverzicht-table-style">
            <thead>
                <tr>
                    <th>Maand</th>
                    <th>Jaar</th>
                    <th>Uren cumulatief</th>
                    <th>Uren totaal</th>
                </tr>
            </thead>
            <tbody>
                {arrMonth.map((value) => {
                    return (
                        <tr>
                            <td>{value.monthName !== undefined ? value.monthName : ''}</td>
                            <td>{value.year !== undefined ? value.year : ''}</td>
                            <td>{value.cumulatief !== undefined ? value.cumulatief : ''}</td>
                            <td>{value.totaal_uren !== undefined ? value.totaal_uren : ''}</td>
                        </tr>
                    )
                })
                }
            </tbody>
        </Table>
    );
};

export default MaandoverzichtTable;