import { React, useState, useEffect } from "react";
import ReactTableGeneric from "Components/ReactTableGeneric";
import DocumentService from "Api/Document";
import { ReactComponent as HomeIcon } from "../../../../assets/utils/images/svgs/home.svg";
import { ReactComponent as FrameIcon } from "../../../../assets/utils/images/svgs/Frame.svg";
import { ReactComponent as Historie } from "../../../../assets/utils/images/svgs/Historie.svg";
import { ReactComponent as Decenteralized } from "../../../../assets/utils/images/svgs/Decenteralized.svg";
import { ReactComponent as Centeralized } from "../../../../assets/utils/images/svgs/Centeralized.svg";
import { ReactComponent as PIcon } from "../../../../assets/utils/images/svgs/iconoir_parking.svg";
import { ReactComponent as GrrayCircle } from "../../../../assets/utils/images/svgs/GrrayCircle.svg";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { BsCheckCircle } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import moment from "moment";
import Loader from "react-loaders";
import TextLimit from "Api/TextLimit";
import ReactTableGenericForUsers from "Components/ReactTableGenericForUsers";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { ReactComponent as ShowSchool } from "../../../../assets/utils/images/svgs/ShowSchool.svg";
import { ReactComponent as RaedSchool } from "../../../../assets/utils/images/svgs/RaedSchool.svg";
import { ReactComponent as Home } from "../../../../assets/utils/images/svgs/home.svg";
import { ReactComponent as Teachershow } from "../../../../assets/utils/images/svgs/Teacher-show.svg";
import { ReactComponent as Teacher } from "../../../../assets/utils/images/svgs/Teacher.svg";
import { ReactComponent as TeacherRead } from "../../../../assets/utils/images/svgs/teacher-read.svg";

const Record = (props) => {

    const iconSwitch = (param) => {
        switch (param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal font-size={"24px"} /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><Historie /></span></Tippy >;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><PIcon /></Tippy>;
            // case 'Aanmaken':
            //     return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"} /></span></Tippy>;
            default:
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"} /></span></Tippy>;
        }
    }

    const homeIconSwitch = (value) => {
        switch (value) {
            case 'Yes':
                return <Home />;
            case 'Yes1':
                return <ShowSchool />;
            default:
                return '';
        }
    }

    const userIconSwitch = (value) => {
        switch (value) {
            case 'Yes':
                return <Teacher />;
            case 'Yes1':
                return <Teachershow />;
            default:
                return '';
        }
    }

    const columns = [
        {
            header: () => 'ID',
            cell: value => {
                return (
                    <Link
                        to={`/verzoeken/scherm/${value.row.original.iSubstitutionId}`}
                        key={value}
                    >
                        {value.row.original.iSubstitutionId}
                    </Link>)
            },
            accessorKey: 'iSubstitutionId',
        },
        {
            header: () => 'K',
            accessorKey: 'k',
            cell: value => (
                    <GrrayCircle fill={value.row.original.markereColor !== null ? value.row.original.markereColor : '#878EA6'}/>
            )
        },
        {
            header: () => 'M',
            accessorKey: 'm',
            cell: value => (
                    homeIconSwitch(value.row.original.eOpmerkingen)
            )
        },
        {
            header: () => 'V',
            accessorKey: 'v',
            cell: value => (
                userIconSwitch(value.row.original.eMarkeren)
            )
        },
        {
            header: () => 'Datum',
            accessorKey: 'dAddedByDir',
        },
        {
            header: () => 'Organisatie',
            accessorKey: 'vOrgName',
        },
        {
            header: () => 'School',
            accessorKey: 'vSchoolName',
            cell: value => (
                window.innerWidth > 1250 ? 
                TextLimit.limitStringLargeScreen(value.row.original.vSchoolName)
                : TextLimit.limitStringSmallScreen(value.row.original.vSchoolName)
            )
        },
        {
            header: () => 'Ll',
            accessorKey: 'iLeerlingen',
        },
        {
            header: () => 'Afwezige',
            accessorKey: 'Vervanger',
            cell: (value) => {
                return (
                    window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                        :TextLimit.AfwezigeLimitOptionTwo(value.row.original.vFirstName, value.row.original.vMiddleName, value.row.original.vLastName)
                )
            }
        },
        {
            header: () => 'Code',
            accessorKey: 'vCode',
            cell: value => (
                <Tippy content={value.row.original.vCodeValue} placement="bottom"><span>{
                    TextLimit.codeLimitOptionOne(value.row.original.vCode)
                    }</span></Tippy>
            ),
        },
        {
            header: () => 'Functies',
            accessorKey: 'groupName',
            cell: value => (
                window.innerWidth > 1250 ? TextLimit.limitFunctiesLargeScreen(value.row.original.groupName)
                :TextLimit.limitFunctiesSmallScreen(value.row.original.groupName)
            ),
        },
        {
            header: () => '1e dag',
            accessorKey: 'vSubsDate',
            cell: value => (
                value.row.original.vSubsDate
            ),
        },
        {
            header: () => '1e niet',
            accessorKey: 'gvSubsDate',
        },
        {
            header: () => 'P-Datum',
            accessorKey: 'dParkdate',
            cell: value => (
                (value.row.original.dParkdate !== '0000-00-00' && value.row.original.dParkdate !== null) ? moment(value.row.original.dParkdate).format('DD-MM-YY') : ''
            )
        },
        {
            header: () => 'P',
            accessorKey: 'type',
            cell: value => (
                value.row.original.iPlanOption === 0 || value.row.original.iPlanOption === 3 ?
                    <Tippy content={'Centrale Planning'} placement="bottom"><Centeralized /></Tippy> : <Tippy content={'School Planning'} placement="bottom"><Decenteralized /></Tippy>
            ),
        },
        {
            header: () => 'S',
            accessorKey: 'eStatus',
            cell: value => (
                value.row.original.eStatus === 'Openstaand' && new Date(value.row.original.dParkdate) >= new Date() ?
                <Tippy content={'Geparkeerd'} placement="bottom"><span><BsCheckCircle color='#39A825' font-size={"21px"} /></span></Tippy>
                : iconSwitch(value.row.original.eStatus)
            )
        },
    ];

    return (
        <>
            { props.priorityListLoader === true &&
                    <Loader type="ball-grid-pulse" className={'mt-3 d-flex mx-auto justify-content-center'} />
            }

            {
                props.priorityList !== undefined && props.priorityList.length > 0 && props.priorityListLoader !== true &&
                <div style={{marginBottom:'15px'}}>
                    <ReactTableGeneric data={props.priorityList} tableClassName="mb-2 table table-sm table-hover"
                        columns={columns} filters={{search: true, pagination: true, sorting: true}}
                    />
                </div>
            }
        
            <ReactTableGenericForUsers data={props.data} tableClassName="mb-0 table table-sm table-hover"
                userTablePageSize={props.userTablePageSize}
                totalPageCount={props.totalPageCount}
                pageNumber={props.pageNumber}
                pageSizeCount={props.pageSizeCount}
                showLoader={props.showLoader}
                setPageNumber={props.setPageNumber}
                paginate={props.paginate}
                filterList={props.filterList}
                columns={columns} filters={{ search: true, pagination: true, sorting: true }} rowCount={20}
            />
        </>
    );
};
export default Record;
