import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

Bugsnag.start({
    apiKey: '01ea4ee16401fd1a550305f104b5e562',
    plugins: [new BugsnagPluginReact()],
    autoDetectErrors: process.env.REACT_APP_ENVIRONMENT || 1 ? false: true,
    releaseStage: process.env.REACT_APP_ENVIRONMENT || 'development'
})

// // Use the provided error boundary to wrap your application:
const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

export default ErrorBoundary;