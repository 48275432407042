import React from 'react';
import ReactTableGeneric from "Components/ReactTableGeneric";
import {useFilters, usePagination, useTable} from 'react-table';
import {Card, CardBody, Pagination, PaginationItem, PaginationLink} from "reactstrap";
import {Link} from "react-router-dom";

const UsersTable = (props) => {

    const data = React.useMemo(
        () => props.data,
        []
    );

    const columns = [
            {
                header: 'Clooser Id',
                accessorKey: 'ID', // accessor is the "key" in the data
                cell: (value) => { 
                    return (
                        <Link
                            to={`/verzoeken/${value.row.original.ID}`}
                            key={value.row.original.ID}
                        >
                            {value.row.original.ID}
                        </Link>)
                },
            },
            {
                header: 'Melddatum',
                accessorKey: 'Melddatum', // accessor is the "key" in the data
            },
            {
                header: 'Organisatie',
                accessorKey: 'Organisatie'
            },
            {
                header: 'School',
                accessorKey: 'School'
            },
            {
                header: '1e dag',
                accessorKey: 'FirstDate'
            },
            {
                header: 'Laatste dag',
                accessorKey: 'LastDate'
            },
            {
                header: 'Code',
                accessorKey: 'Code'
            },
        ]

   
    return (
        <>
            <Card className="main-card mb-3 table-pagination">
                <CardBody>
                    <ReactTableGeneric
                        data={data}
                        columns={columns}
                        tableClassName="mb-0 table table-sm table-hover"
                    />
                </CardBody>
            </Card>
        </>
    )
}

export default UsersTable;