import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Collapse, Nav, NavItem, Popover, Table } from "reactstrap";
import Tippy from "@tippyjs/react";
import LongTermRequestTable from './LongTermRequestTable';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {BsCheckCircle} from "react-icons/bs";
import Historie from "../../../../../assets/utils/images/svgs/Historie.svg";
import map from "lodash/map"
import TextLimit from "Api/TextLimit";

const SubstitutionDetails = ({ substitution, id, longTermRequests }) => {

    const [slideMenuState, setSlideMenuState] = useState(false);
    const [collapseStatus, setCollapseStatus] = useState("Closed");
   
    const onEntered = () => {
        setCollapseStatus("Opened");
    }

    const onExited = () => {
        setCollapseStatus("Closed");
    }

    const toggleCollapsed = () => {
        setSlideMenuState((current) => !current);
    }

    const showSchoolData = (school) => {
        const withAddress = [school.vSchoolName, school.vVisitcity].filter(e => e).join(", ");
        let withCommunity;
        if (school.community.vCommunityName) {
            withCommunity = withAddress.concat(" (", school.community.vCommunityName, ")")
        }
        return school.vSchoolName ? 
            onHover(
                withAddress,
                withCommunity
            ) : "---"
    }

    const onHover = (data, content) => (<Tippy content={content ?? data} placement="bottom"><span>{data}</span></Tippy>)


    const iconSwitch = (param) => {
        switch(param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal fontSize={"26px"} /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><img src={Historie} width="25" /></span></Tippy >;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"22px"}/></span></Tippy>;
            default:
                return '--';
        }
    }

    if (!substitution) return null;
       const howToInfo = substitution.iHowToInformId != null ? substitution.iHowToInformId.replace(' ', '').split(',') : [];

    return (
        <Fragment>
            <Card className="main-card mb-3 sub-detail-table-style">
                <CardBody className="p-3">
                    <Table size="sm" borderless className="mb-0 border-btm-none">
                        <tbody>
                            <tr>
                                <th className="fw-bold col-2">Verzoek</th>
                                <th className="fw-bold col-2">School</th>
                                <th className="fw-bold col-2">Functies</th>
                                <th className="fw-bold col-2">Status</th>
                            </tr>
                            <tr>
                                <td className="wl">
                                    {id + " / " + (substitution.iPlanOption === 3 || substitution.iPlanOption === 0 ? 'School planning' : 'Centrale planning')}
                                </td>

                                <td className="wl">
                                    {showSchoolData(substitution.school)}
                                </td>

                                <td className="wl">
                                    {onHover(map(substitution.groups,'vGroup').join(" "))}
                                </td>

                                <td className="wl">
                                    <Tippy content={substitution?.eStatus} placement="bottom"><span>{iconSwitch(substitution?.eStatus)}</span></Tippy>
                                </td>
                            </tr>

                            <tr>
                                <td className="p-0" colSpan="4">
                                    <Collapse isOpen={slideMenuState} onEntered={onEntered}
                                        onExited={onExited}>
                                        <Table size="sm" borderless className="mb-0">
                                            <tbody>
                                                <tr>
                                                    <th className="fw-bold">Code</th>
                                                    <th className="fw-bold">Afwezige</th>
                                                    <th className="fw-bold">Melding</th>
                                                    <th className="fw-bold">Ketenregeling toepassen</th>
                                                </tr>
                                                <tr>
                                                    <td className="wl">
                                                        <Tippy content={substitution.code?.vCodeValue} placement="bottom"><a href="#"> {TextLimit.codeLimitOptionTwo(substitution.code.vCode, substitution.code?.vCodeValue) ?? "--"} </a></Tippy>
                                                    </td>

                                                    <td className="wl">
                                                        {onHover(substitution.absent !== '' && substitution.absent !== null ? TextLimit.AfwezigeLimitOptionOne(substitution.absent.vFirstName, substitution.absent.vMiddleName, substitution.absent.vLastName) : "--")}
                                                    </td>

                                                    <td className="wl">
                                                        <Tippy content={(substitution.submitter ? substitution?.submitter.vFirstName + " " + substitution?.submitter.vLastName : '--') + " " + substitution?.dAddedByDir} placement="bottom"><a href="#"> {substitution?.submitter ? substitution?.submitter.vFirstName + " " + substitution?.submitter.vLastName : '--' } {substitution?.dAddedByDir}</a></Tippy>                                                    </td>

                                                    <td className="wl">
                                                        {onHover(substitution.eExclude_wwz == '0' ? "JA" : (substitution.eExclude_wwz == '1' ? "NEE" : "--"))}
                                                    </td>

                                                </tr>

                                                <tr>
                                                    <th className="fw-bold">Kostenplaats / project</th>
                                                    <th className="fw-bold">E-mail zakelijk</th>
                                                    <th className="fw-bold">E-mail priv&eacute;</th>
                                                    <th className="fw-bold">Per Sms</th>
                                                </tr>
                                                <tr>
                                                    <td className="wl">
                                                        {onHover(substitution.vKostenplaats ? substitution.vKostenplaats : '--')}
                                                    </td>
                                                    <td className="wl">
                                                        {onHover(substitution.submitter.vBusinessEmail ? substitution.submitter.vBusinessEmail : '--')}
                                                    </td>
                                                    <td className="wl">
                                                        {onHover(substitution.submitter.vEmail && substitution.submitter.vEmail != '' && substitution.submitter.vEmail != null && howToInfo.includes('5') ? substitution.submitter.vEmail : '--')}
                                                    </td>
                                                    <td className="wl">
                                                        {onHover(substitution.sms != '' && substitution.sms != null && howToInfo.includes('3') ? substitution.sms : '--')}
                                                    </td>
                                                    
                                                </tr>
                                                <tr>
                                                    <th className="fw-bold">Extra gebruiker(s) informeren</th>
                                                    <th className="fw-bold">Extra e-mail adres</th>
                                                    
                                                    <th className="fw-bold">Opmerking tbv vervanger</th>
                                                </tr>
                                                <tr>
                                                    <td className="wl">
                                                        {onHover((substitution.vExtraEmail1 != '' && substitution.vExtraEmail1 != null) || (substitution.vExtraEmail2 != '' && substitution.vExtraEmail2 != null) 
                                                        ? (substitution.vExtraEmail1 != '' && substitution.vExtraEmail1 != null ? substitution.vExtraEmail1 : '' ) +
                                                        (substitution.vExtraEmail1 != '' && substitution.vExtraEmail1 != null && substitution.vExtraEmail2 != '' && substitution.vExtraEmail2 != null ? ', ' : '') +
                                                        (substitution.vExtraEmail2 != '' && substitution.vExtraEmail2 != null ? substitution.vExtraEmail2 : '')
                                                        : '--'
                                                        )}
                                                    </td>
                                                    <td className="wl">
                                                        {substitution.OtherHowToInform && substitution.OtherHowToInform != '' && substitution.OtherHowToInform != null ? substitution.OtherHowToInform.replace(',', ', ') : '--'}
                                                    </td>
                                                   
                                                    <td className="wl">
                                                        {onHover(substitution.vSubstitutorComments ? substitution.vSubstitutorComments : '--')}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Collapse>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="4" className="text-center">
                                    <Button outline className="btn-xs btn-table-show btn-color-style"
                                        color="secondary" onClick={toggleCollapsed}>
                                        {collapseStatus === "Closed" ?
                                            <IoIosArrowDown fontSize="12px" color="secondary" /> :
                                            <IoIosArrowUp fontSize="12px" color="secondary" />}
                                        {collapseStatus === "Closed" ? " Meer" : " Minder"}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </CardBody>
            </Card>


            {longTermRequests.length > 0 && 
                <Card className="mb-3">
                    <CardBody>
                        <div className="card-header long-term-request-header">Langdurige Verzoeken</div>
                        <LongTermRequestTable data={longTermRequests} />
                    </CardBody>
                </Card>
            }

        </Fragment>
    )
};

export default SubstitutionDetails;
