import { Fragment, useEffect, useState } from "react";
import BasicInformation from "./BasicInformation";
import AdditionalInformation from "./AdditionalInformation";
import { Col, Container, Row } from "reactstrap";
import axiosInstance from "Api";

const GeneralInformation = ({
    formikProps,
    formDataState,
    setFormDataState,
    isDisabled,
    setIsDisabled,
    organizationsList,
    codeListData,
    schools,
    absentsData,
    groupsData,
    getSchoolsByCommunityId,
    getAbsenceCodeByCommunityId,
    getAbsentsList,
    usersData,
    link,
    setLink
}) => {


    const { values } = formikProps;

    const filteredOrganisation = organizationsList.find(organization => organization.iCommunityId === formikProps.values.organisationId);
    const filteredCode = codeListData.find(code => code.iCodeId == values.codeId);

    useEffect(() => {
        // request for Verlof link
        if (filteredOrganisation && (filteredOrganisation.vVerzuimmeldingLink || filteredOrganisation.vVerlofmeldingLink) && values.schoolId) {
            axiosInstance.get('/organizations/' + filteredOrganisation.iCommunityId + '/codes-link?iSchoolId=' + values.schoolId + (values.absentId && '&absentId=' + values.absentId)).then((response) => {
                if (response.status == 200) {
                    if (response.data.data != null && typeof response.data.data !== 'undefined') {
                        let userData = response.data.data;
                        setLink(userData);
                    }
                }
            }).catch((error) => console.error("catch error:", error));
        } else {setLink('')}
    }, [values.schoolId, values.absentId, filteredOrganisation]);

    return (
        <Fragment>
            <Container>
                <Row>
                    <Col>
                        <BasicInformation
                            formikProps={formikProps}
                            formDataState={formDataState}
                            setFormDataState={setFormDataState}
                            isDisabled={isDisabled}
                            data={organizationsList}
                            codeListData={codeListData}
                            getAbsenceCodeByCommunityId={getAbsenceCodeByCommunityId}
                            schools={schools}
                            absentsData={absentsData}
                            groupsData={groupsData}
                            getSchoolsByCommunityId={getSchoolsByCommunityId}
                            getAbsentsList={getAbsentsList} />
                    </Col>
                    <Col>
                        <AdditionalInformation
                            formikProps={formikProps}
                            formDataState={formDataState}
                            setFormDataState={setFormDataState}
                            isDisabled={isDisabled}
                            codeListData={filteredCode}
                            verlofLink={link}
                            data={usersData}
                            planningId={filteredOrganisation && (filteredOrganisation.planningId ?? 3)} />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
};

export default GeneralInformation;
