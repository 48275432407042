export const SET_USER_PROFILE_PICTURE = 'SET_PROFILE_PICTURE';

export const setUserProfilePicture = profileImage => ({
    type: SET_USER_PROFILE_PICTURE,
    profileImage
});

export default function UserProfile(state = {
    profileImage: ''
}, action){
    switch (action.type) {
        case SET_USER_PROFILE_PICTURE:
            return {
                ...state,
                profileImage: action.profileImage
            };
        
        default:
            return state;
    }
}
