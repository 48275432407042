import React, { Fragment, useState, useEffect } from 'react';
import { Button, Input, Table } from "reactstrap";
import axiosInstance from "Api";
import DefaultButton from "Components/Buttons/DefaultButton";
import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import DefaultModal from 'Components/Modal/DefaultModal';

const passwordSchema = Yup.object().shape({
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
});

const BSNSchema = Yup.object().shape({
    bsn: Yup.number()
        .required('SBN is verplicht')
});

const BSNValidateRegExp = /[A-Za-z\s`!@#$%^&*()_+\-\=\[\]{};':\"\\\|,\.<>\/\?~]+/;
const specialCharactersRegExp = /[`!@#$%^&*()_+\-\=\[\]{};':\"\\\|,\.<>\/\?~]+/;

const UsersModal = ({ userDetails, reporterId, setReporterId }) => {
    // PASSWORD DEALS
    const [generatedPassword, setGeneratedPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordMessage, setPasswordMessage] = useState([]);
    const [showPassword, setShowPassword] = useState(true);

    // BSN DEALS
    const [changeBSN, setChangeBSN] = useState(false);
    const [showBSN, setShowBSN] = useState(false);
    const [newBSN, setNewBSN] = useState('');
    const [BSNMessage, setBSNMessage] = useState('');

    // IF PASSWORD/BSN IS SUCCESSFULLY SAVED
    const [isSuccess, setSuccess] = useState(false);


    useEffect(() => {
        setPasswordMessage([])
        setBSNMessage('');
        setSuccess(false);
    }, [newPassword, newBSN, showBSN])

    const toggle = () => {
        setReporterId(null);
        setNewPassword('');
        setNewBSN('');
        setPasswordMessage([]);
        setBSNMessage('');
        setGeneratedPassword('');
        setShowBSN(false);
        setChangeBSN(false);
    }

    const generator = require('generate-password');
    const generatePassword = () => {
        const password = generator.generate({
            length: 8,
            numbers: true,
            symbols: true,
            lowercase: true,
            uppercase: true,
            strict: true
        });

        setGeneratedPassword(password);
        setNewPassword(password);
    };

    const updatePassword = id => {
        passwordSchema
            .validate({
                password: newPassword
            })
            .then(valid => {
                if (valid) {
                    axiosInstance.patch(`admins/users/` + id, { newPassword }).then((response) => {
                        if (response.data.status === true) {
                            setSuccess(true);
                            setPasswordMessage(['Wachtwoord is succesvol gewijzigd'])
                        }
                    }).catch(error => console.error(error));
                }
            })
            .catch(error => setPasswordMessage(error.errors))
    }

    const updateBSN = (oldBSN) => {
        if (newBSN == oldBSN) {
            setBSNMessage("SBN bestaat al");
        } else if (newBSN.length !== 9) {
            setBSNMessage("SBN moet 9 tekens lang zijn");
        } else {
            BSNSchema
                .validate({
                    bsn: newBSN
                })
                .then(valid => {
                    if (valid) {
                        axiosInstance.patch(`admins/users/` + reporterId, { newBSN }).then((response) => {
                            if (response.data.status === "success") {
                                setSuccess(true);
                                setBSNMessage(['BSN succesvol aangepast'])
                            }
                        }).catch(error => console.error(error));
                    }
                })
                .catch(error => setBSNMessage("Het BSN mag alleen cijfers bevatten"))
        }
    }

    return (
        <DefaultModal modal={reporterId && true} toggle={toggle} showFooterBtns={false}
            classes="modal-dialog__over feedback-modal-style"
        >
            <Table borderless className="mb-0">
                <tbody>
                    {userDetails &&
                        <Fragment>
                            <tr>
                                <th className="py-2 table-heading" scope="row">Name</th>
                                <td className="d-block mx-5 table-data">{userDetails.fullname}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">UserName</th>
                                <td className="d-block mx-5 table-data">{userDetails.vUserName}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th className="py-2 table-heading" scope="row">Enter new password</th>
                                <td className="d-block mx-5 table-data">
                                    <div className='d-flex justify-content-between input-width'>
                                        <Input
                                            type={showPassword ? "text" : "password"}
                                            className={`${isSuccess ? 'active-border' : ''}`}
                                            value={newPassword}
                                            onChange={e => setNewPassword(e.target.value)}
                                        />
                                        <Button className='input-group-append d-inline' color="primary" onClick={() => setShowPassword(!showPassword)}>
                                            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                                        </Button>
                                    </div>
                                    {passwordMessage && <p className={`${isSuccess ? 'text-success' : 'text-danger'}`}>{passwordMessage[0]}</p>}
                                </td>
                                <td className="btn-vertical-align">
                                    <div className="d-flex update-gen-btn">
                                    <DefaultButton type="button" onClick={() => updatePassword(reporterId)} className="py-1 w-50 ">Update</DefaultButton>
                                    <DefaultButton onClick={generatePassword} className="py-1 w-50 ">Genereer</DefaultButton>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th className="table-heading" scope="row">BSN</th>
                                <td className="d-block mx-5 table-data">
                                    {changeBSN && <Input
                                        type="text"
                                        className="form-control"
                                        value={newBSN}
                                        onChange={e => setNewBSN(e.target.value)}
                                    />}
                                    {showBSN && <p>{userDetails.vSocialSecNumber}</p>}
                                    {BSNMessage && <p className={`${isSuccess ? 'text-success' : 'text-danger'}`}>{BSNMessage}</p>}
                                </td>
                                <td className="">
                                    <DefaultButton
                                        onClick={() => {
                                            if (!changeBSN && !showBSN) {
                                                setShowBSN(true);
                                            } else if (showBSN) {
                                                setChangeBSN(true);
                                                setShowBSN(false);
                                            } else if (changeBSN) {
                                                updateBSN(userDetails.vSocialSecNumber)
                                            }
                                        }}
                                        className="py-1 w-100"
                                    >{
                                            showBSN ? "Change" : changeBSN ? "Update" : "Weergeven"
                                        }</DefaultButton>
                                </td>
                            </tr>
                        </Fragment>
                    }
                </tbody>
            </Table>
        </DefaultModal>
    )
}

export default UsersModal;