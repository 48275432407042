import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import { Slider } from "react-burgers";

import AppMobileMenu from "../AppMobileMenu";
import { useDispatch,useSelector } from "react-redux";
import {useProSidebar} from 'react-pro-sidebar';

import {
  setEnableClosedSidebar,
  setEnableMobileMenu,
  setEnableMobileMenuSmall,
} from "../../reducers/ThemeOptions";


  const HeaderLogo = () =>{
    const { collapseSidebar } = useProSidebar();
    const dispatch = useDispatch();

    const EnableClosedSidebar= (enable) => dispatch(setEnableClosedSidebar(enable))
    const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);

    const [active, setActive] = useState(false)

    const toggleEnableClosedSidebar = () => {
      EnableClosedSidebar(!enableClosedSidebar);
      {
        if (!enableClosedSidebar) {
          collapseSidebar()
        } 
        else {
          collapseSidebar(false) 
        }
      }
    };

    return(
      <Fragment>
          <div className="app-header__logo">
            <div className="logo-src" />
            <div className="header__pane ms-auto">
              <div onClick={toggleEnableClosedSidebar}>
                <Slider width={26} lineHeight={2} lineSpacing={5} color="#6c757d"
                  active={active} onClick={()=>setActive(!active)}/>
              </div>
            </div>
          </div>
          <AppMobileMenu />
        </Fragment>
    )
  }

export default HeaderLogo;
