import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "Api";
import PageTitle from "Layout/AppMain/PageTitle";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import SweetAlert from 'react-bootstrap-sweetalert';
import { toast, ToastContainer  } from "react-toastify";
import Loader from "react-loaders";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavLink,
    Row,
    Table,
    UncontrolledButtonDropdown
} from "reactstrap";
// import {IoIosGrid} as Ionicons from "react-icons/io";
import moment from "moment";
import Tippy from "@tippyjs/react";
import SubstitutionDetails from "Pages/Melder/Substitutions/Show/Partials/SubstitutionDetails";
import Chat from "Pages/Melder/Substitutions/Show/Partials/Chat";
import SubstitutionTable from "Pages/Melder/Substitutions/Show/Partials/SubstitutionTable";
import PageHeading from "Layout/AppMain/PageHeading";
import CustomFilter from "Components/ReactTable/Filters/CustomFilters";


const Default = (props) => {
    const [substitution, setSubstitution] = useState(null);
    const [chatActive, setChatActive] = useState(true);
    const [substitutionComments, setSubstitutionComments] = useState([]);
    const [subsDateIds, setSubsDateIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [colMdSize, setcolMdSize] = useState(false);

    const params = useParams();
    let navigate = useNavigate();

    const substitutionId = params.id || '';

    const changeColMdSize = (value) => {
        setcolMdSize(value);
    }
    useEffect(() => {
        if (!substitution) {
            setSpinner(true);

            axiosInstance.get('requests/' + substitutionId).then((response) => {
                if (response.status == 200) {
                    setSubstitution(response.data);
                    const comments = response.data?.result_info.comments.filter(msg => msg.vDirComments);
                    if (comments.length) {
                        setSubstitutionComments(comments);
                    }
                }
            })
            .finally(() => setSpinner(false))
            .catch((error) => console.log("catch error:", error));
        }
    }, [substitution]);

    const deleteDates = () => {
        setIsDeleted(true);
        axiosInstance
            .post('/requisition/' + substitutionId + '/dates',  { ids: subsDateIds })
            .then((response) => {
                if (response.status === 200) {
                    setShowModal(false);
                    setTimeout(() => {
                        setSubstitution({...substitution, converted_dayparts:
                            substitution.converted_dayparts.map(e => {
                                const dayNdataKeys = Object.entries(e);
                                const filteredKeys = dayNdataKeys.map(a => {
                                    const dayPartData = Object.entries(a[1]);
                                    if (dayPartData.length <= 2) {
                                        const data = Object.fromEntries(dayPartData.filter(i => {
                                            if (!subsDateIds.includes(i[1].iSubsDateId)) {
                                                return true
                                            }
                                        }));
                                        return Object.keys(data).length !== 0 && { day: dayNdataKeys[0][1], data }
                                    }
                                })[1];
                                return filteredKeys;
                            }).filter(e => typeof e === 'object')
                        })
                        setSubsDateIds([]);
                        setIsDeleted(false);
                    }, 950);
                }
            })
            .catch((error) => console.log("catch error:", error));
        toast["success"]("Dagdelen zijn succesvol verwijderd!")
    }


    return (
        <>
            <Fragment>

                <TransitionGroup>
                    <CSSTransition component="div" className="TabsAnimation" appear={true}
                                   timeout={1500} enter={false} exit={false}>
                        <div>
                            <div className="chat-layout-wrapper">
                                <div className="app-inner-layout chat-layout" >
                                        <PageHeading/>
                                    <div className="app-inner-layout__wrapper">
                                        <Card className="app-inner-layout__content ">
                                            <Row>
                                                <Col md={window.innerWidth < 1500 ? "12" : "9"} sm="12">
                                                    <SubstitutionDetails substitution={substitution?.result_info} id={substitution?.id} longTermRequests={substitution?.long_term_requests} />
                                                </Col>
                                            </Row>
                                            <div className="chatbox-column-styling">
                                            <Row>
                                                <Col md={window.innerWidth < 1500 ? "12" : "9"} sm="12">
                                                    <Card className="main-card mb-3 ">
                                                        <CardBody className="p-3 d-flex justify-content-center ">
                                                            {spinner ?
                                                                <Loader type="ball-grid-pulse"/>
                                                                :
                                                                substitution?.converted_dayparts?.length ?
                                                                <SubstitutionTable substitution={substitution} subsDateIds={subsDateIds} setSubsDateIds={setSubsDateIds} isDeleted={isDeleted}/>
                                                                :
                                                                <div>The substitution does not have working dates.</div>
                                                            }
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <div className="main-melder-chatbox-scroll">
                                            { chatActive ? <Chat substitutionComments={substitutionComments} setSubstitutionComments={setSubstitutionComments} changeColMdSize={changeColMdSize}/> : null}
                                            </div>
                                           </div>
                                        </Card>
                                    </div>

                                </div>

                                <div className="chat-layout-footer">
                                    <CardFooter className="d-block text-center d-flex chat-layout-footer__inner">
                                        <div className="flex-grow-1 d-flex justify-content-start">
                                            <Button onClick={() => navigate(`/verzoeken/${substitutionId}/dagdelen-toevoegen`)} className="d-flex align-items-center btn-color-style mb-2 me-2" size="sm" outline color="primary">
                                                <i className="nav-link-icon pe-7s-plus mt-1 me-2"> </i>
                                                Dagdelen
                                            </Button>
                                            <Button onClick={() => subsDateIds.length !== 0 ? setShowModal(true) : toast["info"]("Selecteer dagdelen.")} className="d-flex align-items-center btn-color-style mb-2 me-2" size="sm" outline color="primary">
                                                <i className="nav-link-icon pe-7s-trash mt-1 me-2"> </i>
                                                Dagdelen
                                            </Button>
                                            <SweetAlert title="Wilt u de geselecteerde dagdelen verwijderen?" show={showModal}
                                                        type="warning"
                                                        onCancel={() => setShowModal(false)}
                                                        onConfirm={() => deleteDates()}
                                                        cancelBtnText="Nee"
                                                        confirmBtnText="Ja"
                                                        showCancel={true}
                                            />
                                        </div>
                                        <div>
                                            <Button size="sm" outline className="me-2 btn-color-style" color="primary" onClick={() => navigate('/verzoeken')}>
                                                <i className="nav-link-icon me-2 pe-7s-back-2" > </i>
                                                Sluiten
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </div>

                            </div>
                        <ToastContainer style={{top: "70px"}} />
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Fragment>


        </>
    )
}


export default Default;
