import React, {Fragment, useEffect, useState} from "react";
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import {Card, CardBody, Col, Row,} from "reactstrap";
import 'react-datepicker/dist/react-datepicker.css';

import 'tippy.js/dist/tippy.css'; // optional
import PageHeading from "Layout/AppMain/PageHeading";
import CustomFilter from "Components/ReactTable/Filters/CustomFilters";
import ReactTableGeneric from "Components/ReactTableGeneric";
import WeekstaatService from "Api/Weekstaat";
import { Link } from "react-router-dom";
import { iconSwitch } from "../utils";
import TextLimit from "Api/TextLimit";

const fetchData = async () => {
    try {
        return WeekstaatService.getCurrentWeekstaat();
    } catch (error) {
        console.log(error);
    }
}


const Actueel = () => {
    const [data, setData] = useState([]);
    const [filterData, setFilter] = useState([]);
    const [dropdownData, setdropdownData] = useState([]);

    useEffect(() => {
        if (data.length === 0) {
            fetchData().then((response) => {
                if (response.data.weekstaat && response.data.weekstaat.length > 0) {
                    setData(response.data.weekstaat);
                    response.data.weekstaat.forEach(element => {
                        element.filterName = element.school;
                        element.filterId = element.school_id;
                    });
                    setFilter(response.data.weekstaat);
                    //Set Array unique for Select Dropdown Based upon school_id
                    const arrayUniqueByKey = [...new Map(response.data.weekstaat.map(item => [item["school_id"], item])).values()];
                    setdropdownData(arrayUniqueByKey);
                }
            });
        }
    }, [data]);


    const handleSelect = (seletedValue) => {
        let updatedList = seletedValue ? filterData.filter((value) => value.school_id == seletedValue) : filterData;
        setData(updatedList);
    }

    const downloadReport = (id) => {
        WeekstaatService.downloadReport(id).then((response) => {
            if( response.data.file.download !== ''){
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                const fileName = "weekstaat-report.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const columns = React.useMemo(
        () => [
            {
                header: () => 'Id',
                cell: (value) => {
                    return (
                        <Link
                            to={`/weekstaat/${value.row.original.id}`}
                            key={value.row.original.id}
                            state={{wsId: value.row.original.id, wid: value.row.original.wid, schoolId: value.row.original.school_id}}
                        >
                            {value.row.original.id}
                        </Link>)
                },
                accessorKey: 'id',

            },
            {
                header: () => 'Organisatie',
                accessorKey: 'organization',
                cell: (value) => {
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.organization)
                        : TextLimit.limitStringSmallScreen(value.row.original.organization)
                    )
                }

            },
            {
                header: () => 'School',
                accessorKey: 'school',
                cell: (value) => {
                    return (
                        window.innerWidth > 1250 ?
                        TextLimit.limitStringLargeScreen(value.row.original.school)
                        : TextLimit.limitStringSmallScreen(value.row.original.school)
                    )
                }
            },
            {
                header: () => 'Begindatum',
                accessorKey: 'start_date',

            },
            {
                header: () => 'Einddatum',
                accessorKey: 'end_date',

            },
            {
                header: () => 'Datum/tijd weekstaat',
                accessorKey: 'created',

            },
            {
                header: () => 'Aangemaakt door',
                accessorKey: 'coordinator',
            },
            {
                header: () => 'Status',
                accessorKey: 'status',
                cell: value =>(iconSwitch(value.row.original.status))
            },
            {
                header: () => 'Actie',
                accessorKey: 'pdf',
                cell: value => value.row.original.filename !== null ? (<a href="javascript:void(0)" onClick={()=>downloadReport(value.row.original.wid)} className="anchor-action-link">
                    <div className="font-icon-wrapper download-button-style"><i className="pe-7s-download btn-icon-wrapper"> </i></div>
                </a>) : (''),
            },
        ],
        []
    );

    return (
        <Fragment>
            {/* <PageTitle heading="Actueel"
                       icon="pe-7s-bluetooth icon-gradient bg-happy-fisher"/> */}
            <PageHeading>
                {
                    data.length > 0 ? <CustomFilter data={dropdownData} handleCallback={handleSelect}/> : null
                }
            </PageHeading>
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                               timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3">
                                        <ReactTableGeneric columns={columns} data={data}
                                                           tableClassName="mb-0 table table-borderless table-sm table-hover icon-center"/>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </CSSTransition>
            </TransitionGroup>
        </Fragment>
    )
}

export default Actueel;
