import { useState, useEffect, useRef  } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const ProfilePictureCrop = ({isOpen, file, togglePopup, croppedFile, classes }) => {

    const [image, setImage] =  useState("");
    const cropperRef = useRef(null);

    useEffect(() => {

        if(file !== ''){

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
              setImage(reader.result);
            };
        }
      }, [file]);

    function toggle(){
        togglePopup(!isOpen);
    }

    const handleCroppedImage = () => {
        const imageElement = cropperRef?.current;
        const cropper = imageElement?.cropper;
        croppedFile(cropper.getCroppedCanvas().toDataURL() );
    }

    return (
        <Modal isOpen={isOpen} toggle={toggle} className={classes} >
          <ModalHeader>Foto bijsnijden </ModalHeader>
          <ModalBody>
            <Cropper
                ref={cropperRef}
                src={image}
                style={{ height: 400, width: "100%" }}
                aspectRatio={1 / 1}
                guides={false}
                />
          </ModalBody>
          <ModalFooter>
            <Button color="link" className="btn-color-style" onClick={toggle}>
              Annuleren
            </Button>
            <Button color="primary" className="btn-color-style" onClick={handleCroppedImage}>
              Bijsnijden
            </Button>
          </ModalFooter>
        </Modal>
    );
}

export default ProfilePictureCrop;
