import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";


// Layout
import AppHeader from "../../../Layout/AppHeader/";
import AppSidebar from "../../../Layout/AppSidebar/";



// Components
import AppFooter from "Layout/AppFooter";
import ContactDetails from "./ContactDetails";


const Support = ({ match }) => {
    return (
        <Fragment>
            <AppHeader />
            <div className="app-main">
                <AppSidebar />
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        <ContactDetails/>
                    </div>
                </div>
            </div>
            <AppFooter />
        </Fragment>
    )
};

export default Support;