import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

const WeekReportActueelNavItem = () => {
  const weekstaatCount = useSelector((state) => state.MenuCount.weekstaatCount);

  return (
    <span className='show-item-count'> Weekstaat
      {weekstaatCount > 0 && ' (' +weekstaatCount + ')'}  </span>
  );
}

export default WeekReportActueelNavItem;
