import React, { useEffect, useState, useRef } from "react";
import axiosInstance from "Api";
import ExportService from "Api/Export";

import {
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ReactTableGeneric from "Components/ReactTableGeneric";
import { format } from "date-fns";
import ExportForm from "./ExportForm";
import TextLimit from "Api/TextLimit";

const options = [{ value: "dagdelen", name: "Dagdelen" }];

const Compose = () => {
  var date = new Date();
  let setEndDateperiod = date.setMonth(date.getMonth() + 1);

  const [data, setData] = useState([]);
  const [communityId, setcommunityId] = useState(null);
  const [schools, setSchools] = useState([]);
  const [exportList, setexportList] = useState([]);
  const [beginDate, setBeginDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(setEndDateperiod));

  const formikRef = useRef();


  useEffect(() => {
    getOrganizationsList();
    getExportList();

    const interval = setInterval(() => {
      getExportList();
    }, 20000);
    return () => {
      clearTimeout(interval);
    };
  }, []);

  const initialValues = {
    type: "Dagdelen",
    organization: "",
    school: "",
    dateBeginPeriod: beginDate,
    dateEndPeriod: endDate,
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Veld mag niet leeg zijn"),
    organization: Yup.string().required("Veld mag niet leeg zijn"),
    school: Yup.string().required("Veld mag niet leeg zijn"),
    dateBeginPeriod: Yup.date().required("Veld mag niet leeg zijn"),
    dateEndPeriod: Yup.date().min(
      Yup.ref("dateBeginPeriod"),
      "End date can't be before Start date"
    ),
  });

  const columns = React.useMemo(
    () => [
      {
        header: () => "ID",
        accessorKey: "id", // accessor is the "key" in the data
      },
      {
        header: () => "Bestuur",
        accessorKey: "vCommunityName", // accessor is the "key" in the data
        cell: (value) => {
          return (
              window.innerWidth > 1250 ?
              TextLimit.limitStringLargeScreen(value.row.original.vCommunityName)
              : TextLimit.limitStringSmallScreen(value.row.original.vCommunityName)
          )
      }
      },
      {
        header: () => "Coordinator",
        accessorKey: "vFirstName", // accessor is the "key" in the data
      },
      {
        header: () => "Aanmaakdatum",
        accessorKey: "Created_At",
      },
      {
        header: () => "Actie",
        accessorKey: "iStatus",

        

        cell: (cell) =>
          cell.row.original.iStatus ? (
                <a href="javascript:void(0)" onClick={() => downloadFile(cell.row.original)} className="anchor-action-link">
                  <div className="font-icon-wrapper weekstaat-table-button"><i className="pe-7s-download btn-icon-wrapper"> </i></div>
                </a>
          ) : (
            "......."
          ),
      },
    ],
    []
  );

  const getExportList = () => {
    ExportService.getExportList()
      .then((response) => {
        if (response.status == 200) {
          if (response.data.data != null && response.data.data != undefined) {
            setexportList(response.data.data);
          }
        }
      })
      .catch((error) => console.log("catch error:", error));
  };

  const getOrganizationsList = () => {
    axiosInstance
      .get("organizations-list")
      .then((response) => {
        if (response.status == 200) {
          if (response.data.data != null && response.data.data != undefined) {
            setData(response.data.data);
          }
        }
      })
      .catch((error) => console.log("catch error:", error));
  };

  const downloadFile = (data) => {
    let postData = {
      id: data.id,
      verifier: data.verifier,
    };
    axiosInstance
      .get("document/" + postData.id + "/export")
      .then((response) => {
        console.log(response);
        if (response.data.status == true && response.data.file.download !== "") {
            const linkSource = response.data.file.download;
            const downloadLink = document.createElement("a");
            const fileName = "export-report." + response.data.file.extension;

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        }
      })
      .catch((error) => console.log("catch error:", error));
  };

  const handleSubmit = (data) => {
    console.log("Submitting");
    console.log(data);
    let postdata = { ...data };
    console.log("postdata status ", postdata);
    try {
      postdata.organization =
        data.organization == postdata
          ? data[0].organization
          : data.organization;
      postdata.organization = [postdata.organization];
      postdata.dateBeginPeriod = format(
        new Date(data.dateBeginPeriod),
        "yyyy-MM-dd"
      );
      postdata.dateEndPeriod = format(
        new Date(data.dateEndPeriod),
        "yyyy-MM-dd"
      );

      if (postdata != null) {
        ExportService.insertExportList(postdata)
          .then((response) => {
            if (response.status == "200") {
              console.log("api testing");
              getExportList();
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("errror ");
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Row className="">
        <Col lg="12" className=" pr-0">
          <CardBody>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              isSubmitting={true}
              onSubmit={(data) => {
                handleSubmit(data);
              }}
            >
              <ExportForm/>
            </Formik>
          </CardBody>
        </Col>
      </Row>
      <Row className="">
        <Col lg="12" className="">
          <ReactTableGeneric
            data={exportList}
            columns={columns}
            tableClassName="mb-0 table table-borderless table-sm table-hover"
          />
        </Col>
      </Row>
    </div>
  );
};
export default Compose;
