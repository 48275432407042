import DefaultButton from "Components/Buttons/DefaultButton";
import { Fragment, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import axiosInstance from "Api";
import classNames from "classnames";


const WeekReportForm = ({ status, message }) => {
    const [isAccepted, setIsAccepted] = useState(true);
    const [text, setText] = useState('');
    const [textError, setTextError] = useState(false);
    const params = useParams();
    let navigate = useNavigate();
    let { state } = useLocation();
    
    const rejected = status ? status === 'Afgekeurd' : false;
    const approved = status ? status === 'Goedgekeurd' : false;

    const handleSubmit = (e) => {
        e.preventDefault()
        const weekReportId = params.id || '';

        const msg = {
            declineMessage: text,
            accepted: isAccepted===true ? 1 : 2
        }

        if (!isAccepted && text.length === 0) {
            setTextError(true);
        } else {
            setTextError(false);
            axiosInstance.patch('weekstaat-schools/' + state.wsId, msg)
                .then((response) => {
                    // setText('');
                    navigate("/weekstaat");
                })
                .catch(error => {
                    if (error.status === 422) {
                        console.error(error.data.message);
                    } console.error(error);
                });
        }
    }

    return (
        <Card>
            <CardBody>
            <form onSubmit={(e) => handleSubmit(e)}>
                {(!isAccepted || (rejected && !approved)) &&
                    <div className="mx-4">
                        {textError &&
                            <p className="text-danger">The decline message field is required.</p>
                        }
                        {!rejected &&
                            <p className="mb-1">Geef toelichting op reden van afkeur</p>
                        }
                        <textarea
                            disabled={rejected}
                            value={rejected ? message : text}
                            onChange={(e) => {setText(e.target.value); setTextError(false)}}
                            className={classNames("w-100", {"alert alert-danger": rejected})}
                            rows="4"
                        ></textarea>
                    </div>
                }
                <div className="d-flex align-items-center flex-column">
                {
                    (!rejected && !approved) ?
                    <Fragment>
                        <div className="my-3">
                            <input 
                                onChange={() => setIsAccepted(true)}
                                className="cursor-pointer"
                                id="goedkeuren"
                                type="radio"
                                name="weekReport"
                                value="accepted"/>
                            <label className="mx-2 me-5 cursor-pointer" htmlFor="goedkeuren">Weekstaat goedkeuren</label>
                            <input 
                                onChange={() => setIsAccepted(false)} 
                                className="cursor-pointer"
                                id="afkeuren"
                                type="radio"
                                name="weekReport"
                                value="declined"/>
                            <label className="mx-2 cursor-pointer" htmlFor="afkeuren">Weekstaat afkeuren</label>
                        </div>
                        <DefaultButton className="align-self-center btn-color-style" type="submit">Toepassen</DefaultButton>
                    </Fragment> :

                    <DefaultButton className="align-self-center btn-color-style" onClick={() => navigate('/weekstaat')}>Sluiten</DefaultButton>
                }
                </div>
            </form>
            </CardBody>
        </Card>
    )
}

export default WeekReportForm;
