import React, { Fragment, useState } from "react";
import { Card, CardBody, Collapse, Table, Button } from "reactstrap";
import Tippy from "@tippyjs/react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import WeekstaatService from "Api/Weekstaat";
import { iconSwitch } from "Pages/Melder/WeekReport/utils";
import { format } from 'date-fns';
import moment from "moment";
import TextLimit from "Api/TextLimit";

const WeekReportDetails = ({ reportData }) => {
    const [slideMenuState, setSlideMenuState] = useState(false);
    const [collapseStatus, setCollapseStatus] = useState("Closed");

    const onEntered = () => {
        setCollapseStatus("Opened");
    }

    const onExited = () => {
        setCollapseStatus("Closed");
    }

    const toggleCollapsed = () => {
        setSlideMenuState((current) => !current);
    }

    const downloadReport = (id) => {
        WeekstaatService.downloadReport(id).then((response) => {
            if( response.data.file.download !== ''){
                const linkSource = response.data.file.download;
                const downloadLink = document.createElement("a");
                const fileName = "weekstaat-report.pdf";

                downloadLink.href = linkSource;
                downloadLink.download = fileName;
                downloadLink.click();
            }
        }).catch(() => console.error);
    }

    const showSchoolData = (school) => {
        const withAddress = [school.school, school.vVisitoraddress].filter(e => e).join(", ");
        let withCommunity;
        if (school.communityName) {
            withCommunity = withAddress.concat(" (", school.communityName, ")")
        }
        return school.school ? 
            onHover(
                withAddress,
                withCommunity
            ) : "---"
    }

    const onHover = (data, content) => (<Tippy content={content ?? data} placement="bottom"><span>{data}</span></Tippy>)

    if (!reportData) return null;
    return (
        <Fragment>
            <Card className="main-card mb-3">
                <CardBody className="p-3">
                    <Table size="sm" borderless className="mb-0 border-btm-none">
                        <tbody>
                            <tr>
                                <th>School</th>
                                <th>Geselecteerde periode</th>
                                <th>Download</th>
                                <th>Status</th>
                            </tr>
                            <tr>
                                <td className="wl">
                                    {showSchoolData(reportData)}
                                </td>
                                <td className="wl">
                                    {reportData.period ? onHover(reportData.period) : "---"}
                                </td>
                                <td className="wl">
                                    <a href="#" onClick={()=>downloadReport(reportData.wsId)}  download><i className="lnr-download"> </i></a>
                                </td>
                                <td className="wl px-3">
                                    {iconSwitch(reportData.status)}
                                </td>
                            </tr>
                        <tr> 
                            <td className="p-0" colSpan="4">
                                <Collapse isOpen={slideMenuState} onEntered={onEntered}
                                          onExited={onExited}>
                                    <Table size="sm" borderless className="mb-0 mt-3">
                                        <tbody>
                                            <tr>
                                                <th>Aangemaakt door</th>
                                                <th>Afgemeld door</th>
                                                <th>Goedgekeurd door</th>
                                                <th>Verstuurd naar</th>
                                            </tr>
                                            <tr>
                                                <td className="wl">
                                                    {reportData.createdBy?.replace(/\-/g, "") ? onHover(reportData.createdBy + " (" + reportData.createdDate + ")") : "---"}
                                                </td>
                                                <td className="wl">
                                                    {reportData.cloosedBy?.replace(/\-/g, "") ? onHover(reportData.cloosedBy + " (" + reportData.cloosedDate + ")") : "---"}
                                                </td>
                                                <td className="wl">
                                                    {reportData.acceptedBy?.replace(/\-/g, "") ? onHover(
                                                        (window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(reportData.vFirstName, reportData.vMiddleName, reportData.vLastName)
                                                        :TextLimit.AfwezigeLimitOptionTwo(reportData.vFirstName, reportData.vMiddleName, reportData.vLastName))
                                                        + " (" + moment(new Date(reportData.acceptedDate).toLocaleString()).format("DD-MM-YYYY") + ")") : "---"}
                                                </td>
                                                <td className="wl">
                                                    {reportData.emails?.toString() ? onHover(reportData.emails.toString()) : "---"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Collapse>
                            </td>
                        </tr>

                            <tr>
                                <td colSpan="4" className="text-center">
                                    <Button outline className="btn-xs btn-table-show btn-color-style"
                                        color="secondary" onClick={toggleCollapsed}>
                                        {collapseStatus === "Closed" ?
                                            <IoIosArrowDown fontSize="12px" color="secondary" /> :
                                            <IoIosArrowUp fontSize="12px" color="secondary" />}
                                        {collapseStatus === "Closed" ? " Meer" : " Minder"}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </CardBody>
            </Card>

        </Fragment>
    )
};

export default WeekReportDetails;
