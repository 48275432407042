import axiosInstance from 'Api';

const getExportList = () => {
    return axiosInstance.get("export");
};

const insertExportList = (data) => {
    return axiosInstance.post("export", data);
};

const ExportService = {
    getExportList,
    insertExportList
};
export default ExportService;