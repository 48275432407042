import "./polyfills";
import React from "react";
import ErrorBoundary from "./config/configureBugsnag";
import * as serviceWorker from "./serviceWorker";
import { HashRouter } from "react-router-dom";
import "./assets/base.scss";
import Main from "./Pages/Main";
import configureStore from './config/configureStore';
import InitReduxState from './services/InitReduxState';
import { createRoot } from 'react-dom/client';
import {Provider} from "react-redux";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

InitReduxState.InitUserState(configureStore);

const renderApp = (Component) => {
    root.render(
            <Provider store={configureStore}>
                <HashRouter>
                    { process.env.REACT_APP_DISABLE_BUGSNAG == 1
                     ? 
                     <Component/>
                    : 
                    <ErrorBoundary>
                        <Component/>
                    </ErrorBoundary>
                    }
                </HashRouter>
            </Provider>
    );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./Pages/Main", () => {
    const NextApp = require("./Pages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
