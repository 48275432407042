export const Colors = Object.freeze({
  LightYellow: "F8F2DF",
  LightPink: "FCE9FA",
  LightGreen: "E1F5D8",
  BrightBlue: "D4F3F5",
  LavenderBlue: "C1D0F6",
  BrightPink: "FDDDDD",
  LightBlue: "D3E3EF",
  BrightYellow: "EFF5C5",
  PalePurple: "EAEDF6",
  DarkBlue: "BEDDF5",
  Beige: "E3E1D0",
  DarkGreen: "C0EBD4",
});