import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import FeedbackNavItem from "./CustomNavItems/FeedbackNavItem";
import WeekReportActueelNavItem from "./CustomNavItems/WeekReportActueelNavItem";
import HomeItemNavItem from "./CustomNavItems/HomeItemNavItem";
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import HasPermission from 'Components/Router/Permission';
import AuthService from "Api/Auth";
import { setSubMenuClass } from 'reducers/ThemeOptions';
import { ReactComponent as NotesIcon } from "../../assets/utils/images/svgs/notes.svg";
import { coordinatorPath } from "./helpers";

const Layout = () => {

  const enableClosedSidebar = useSelector((state) => state.ThemeOptions.enableClosedSidebar);

  // get user roles from redux
  const userRoles = useSelector((state) => state.UserPermission);
  const isAllowed = [...userRoles.roles].includes('administrator');

  // get user roles from local storage
  // const isAllowed = localStorage.getItem('USER_ROLES').includes('administrator');

  const location = useLocation();
  const dispatch = useDispatch();

  const getActiveStylingClassName = (mainPage, defaultClass = 'fw-normal') => {
    const path = location.pathname;
    const userRoles = AuthService.getUserRoles();

    if (userRoles.includes("coordinator") && mainPage.some(page => path.includes(page))) {
      return `app-sidebar__item--coordinator ${defaultClass}`;
    }
    return '';
  };

  let navigate = useNavigate();

  const sidebarNewStyle = {
    fontSize: 16
  };

  const iconStyle = {
    fontSize: 24
  }

  function handleWeekstatenClick(open) {
    if (location.pathname == '/weekstaat/actueel') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/weekstaat/actueel");
  }
  function handleVerzoekenClick() {
    if (location.pathname == '/verzoeken') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/verzoeken");
  }
  function handleIndienenClick() {
    if (location.pathname == '/indienen') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/indienen");
  }
  function handleBeheerClick() {
    if (location.pathname == '/beheerder/gebruikers' || location.pathname == '/beheerder') {
      dispatch(setSubMenuClass(' show-submenu '));
    } else {
      dispatch(setSubMenuClass(' hidden-submenu '));
    }
    navigate("/beheerder/gebruikers");
  }
  function handleVervangingenClick() {
    navigate("/vervangingen/beschikbaarheid");
  }
  function handleCoordinatorClick() {
    navigate("/verzoeken/scherm");
  }

  return (
    <Sidebar className='new-inner-sidebar small-menu-styling' style={{ borderRightWidth: 0 }} backgroundColor="white" defaultCollapsed={enableClosedSidebar || window.innerWidth <= 1249}>
      <Menu style={sidebarNewStyle} >


        <HasPermission showFor="requestor">

          {
            AuthService.getUserRoles().length > 1 &&
            <h2 className="px-5 app-sidebar__heading sidebar-roles">Melder</h2>
          }

          <SubMenu label="Verzoeken" onOpenChange={handleVerzoekenClick}
            defaultOpen={location.pathname === "/verzoeken"}
            active={location.pathname === "/verzoeken"}
            icon={<i className="metismenu-icon pe-7s-home" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/verzoeken/historie" />} active={location.pathname === "/verzoeken/historie"}
              icon={<i className="metismenu-icon pe-7s-server" style={iconStyle}></i>}>Historie</MenuItem>
          </SubMenu>

          <SubMenu label="Indienen" onOpenChange={handleIndienenClick} icon={<i className="metismenu-icon pe-7s-magic-wand" style={iconStyle}></i>}
            defaultOpen={location.pathname === "/indienen"}
            active={location.pathname === "/indienen"}
          >
            <MenuItem component={<a target="_blank" href="https://fd8.formdesk.com/cloosernl/vacatures" />}
              icon={<i className="metismenu-icon pe-7s-clock" style={iconStyle}></i>}>Lange vacature</MenuItem>
          </SubMenu>
          <SubMenu label={<WeekReportActueelNavItem />} onOpenChange={handleWeekstatenClick}
            defaultOpen={location.pathname === "/weekstaat/actueel"}
            active={location.pathname === "/weekstaat/actueel"}
            icon={<i className="metismenu-icon pe-7s-browser" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/weekstaat/historie" />} active={location.pathname === "/weekstaat/historie"}
              icon={<i className="metismenu-icon pe-7s-server" style={iconStyle}></i>}>Historie</MenuItem>
          </SubMenu>
          <MenuItem component={<Link to="/Documenten" />} active={location.pathname === "/Documenten"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-id" style={iconStyle} ></i>}> Documenten</MenuItem>
          <MenuItem label="Terugkoppeling" component={<Link to="/terugkoppeling" />} active={location.pathname === "/terugkoppeling"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-loop" style={iconStyle} ></i>}><FeedbackNavItem /></MenuItem>
          <MenuItem component={<Link to="/export" />} active={location.pathname === "/export"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-download" style={iconStyle}></i>}> Export</MenuItem>
          <MenuItem component={<a target="_blank" href="https://fd8.formdesk.com/cloosernl/vervangersmap" />} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-portfolio" style={iconStyle}></i>}> Vervangersmap</MenuItem>
          <MenuItem component={<Link to="/support" />} active={location.pathname === "/support"} onClick={() => dispatch(setSubMenuClass(' hidden-submenu '))} icon={<i className="metismenu-icon pe-7s-help2" style={iconStyle}></i>}> Support</MenuItem>
        </HasPermission>

        <HasPermission showFor="substitutor">
          {
            AuthService.getUserRoles().length > 1 &&
            <h2 className="px-5 app-sidebar__heading sidebar-roles">Vervanger</h2>
          }

          <MenuItem component={<Link to="/home" />} active={location.pathname === "/home"} icon={<i className="metismenu-icon pe-7s-help2" style={iconStyle}></i>}> <HomeItemNavItem /></MenuItem>
          <MenuItem component={<Link to="/beschikbaarheid" />} active={location.pathname === "/beschikbaarheid"}
            icon={<i className="metismenu-icon pe-7s-users" style={iconStyle}></i>}>Beschikbaarheid</MenuItem>
          <MenuItem component={<Link to="/documentenbeheer" />} active={location.pathname === "/documentenbeheer"} icon={<i className="metismenu-icon pe-7s-id" style={iconStyle}></i>}> Documentenbeheer</MenuItem>
          <MenuItem component={<Link to="/urenoverzicht" />} active={location.pathname === "/urenoverzicht"} icon={<i className="metismenu-icon pe-7s-help2" style={iconStyle}></i>}> Urenoverzicht</MenuItem>
          <MenuItem component={<Link to="/Instellingen" />} active={location.pathname === "/Instellingen"} icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>}> Instellingen</MenuItem>
        </HasPermission>



        <HasPermission showFor="coordinator">
          {AuthService.getUserRoles().length > 1 && (
              <h2 className="px-5 pt-3 app-sidebar__heading sidebar-roles">Coordinator</h2>
          )}
          <SubMenu label="Verzoeken" onOpenChange={handleCoordinatorClick} defaultOpen={location.pathname === "/verzoeken/scherm"} active={location.pathname === "/verzoeken/scherm"} icon={<NotesIcon />}className={getActiveStylingClassName(coordinatorPath['verzoeken'], 'fw-bolder')}
          >
            <MenuItem component={<Link to="/verzoeken/planbord" />} defaultOpen={location.pathname === "/verzoeken/planbord"} active={location.pathname === "/verzoeken/planbord"} className={getActiveStylingClassName(coordinatorPath['verzoeken'])}>Planbord</MenuItem>
            <MenuItem component={<Link to="/verzoeken/indienen" />} active={location.pathname === "/verzoeken/indienen"} className={getActiveStylingClassName(coordinatorPath['verzoeken'])}>Indienen verzoek</MenuItem>
          </SubMenu>
        </HasPermission>

        <HasPermission showFor="administrator">
          <SubMenu label="Beheer" onOpenChange={handleBeheerClick}
            defaultOpen={location.pathname === "/beheerder/gebruikers"}
            active={location.pathname === "/beheerder/gebruikers" || location.pathname === "/beheerder"}
            icon={<i className="metismenu-icon pe-7s-config" style={iconStyle}></i>} >
            <MenuItem component={<Link to="/beheerder/gebruikers" />} active={location.pathname === "/beheerder/gebruikers"}
              icon={<i className="metismenu-icon pe-7s-users" style={iconStyle}></i>}>Gebruikers</MenuItem>
            <MenuItem component={<Link to="/beheerder/logging" />} active={location.pathname === "/beheerder/logging"}
              icon={<i className="metismenu-icon pe-7s-display2" style={iconStyle}></i>}>Logging</MenuItem>
            <MenuItem component={<Link to="/beheerder/general-settings" />} active={location.pathname === "/beheerder/general-settings"}
              icon={<i className="metismenu-icon pe-7s-settings" style={iconStyle}></i>}>General Settings</MenuItem>
          </SubMenu>
        </HasPermission>

      </Menu>
    </Sidebar>
  );
}

export default Layout;
