import React, { Fragment } from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useLocation,useMatch } from "react-router-dom";

const AppBreadcrumb = ({ additionalBreadcrumbs, useDefaultPath = true }) => {

    const route = useLocation();
    const breadcrumbs = useBreadcrumbs();
    // console.log(breadcrumbs);
    let match = useMatch("/searchsubrequests/:substitute");
      
    return (
        <div>
            <div className="breadcrumb-main">
                <Breadcrumb color="light">
                    <BreadcrumbItem color="light">
                        <a href="#/verzoeken" >
                            <FontAwesomeIcon icon={faHome} />
                        </a>
                    </BreadcrumbItem>
                    {useDefaultPath
                      ? breadcrumbs.map((breadcrumb, index) => {
                        if (breadcrumb.breadcrumb.props.children == "Searchsubrequests" ) {
                          
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        <span>Zoek resulaten</span>
                                    </a>
                                </BreadcrumbItem>

                            )

                        }else if (breadcrumb.breadcrumb.props.children != "Home" ) {
                          
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        { match !== null ? (
                                            <span>{route.state.name}</span>
                                        ) : (
                                            <span>{breadcrumb.breadcrumb.props.children} </span>
                                        )
                                        }
                                    </a>
                                </BreadcrumbItem>

                            )

                        }else  if (breadcrumb.breadcrumb.props.children == "Home" && index == 1 ) {
                            return (
                                <BreadcrumbItem key={index} color="light">
                                    <a href={"#" + breadcrumb.key} >
                                        { match !== null ? (
                                            <span>{route.state.name}</span>
                                        ) : (
                                            <span>{breadcrumb.breadcrumb.props.children} </span>
                                        )
                                        }
                                    </a>
                                </BreadcrumbItem>

                            )
                        }
                    })
                    : additionalBreadcrumbs &&
                      additionalBreadcrumbs.map((breadcrumb) => (
                        <BreadcrumbItem key={breadcrumb.href} color="light">
                          <a href={breadcrumb.href}>{breadcrumb.label}</a>
                        </BreadcrumbItem>
                      ))}
                </Breadcrumb>
            </div>
        </div>
    );

}

export default AppBreadcrumb;
