export const UPDATE_SUBSTITUTION_FORMS = 'UPDATE_SUBSTITUTION_FORMS';
export const RESET_SUBSTITUTION_FORMS = 'RESET_SUBSTITUTION_FORMS';
export const ADD_DATE = 'ADD_DATE';
export const REMOVE_DATE = 'REMOVE_DATE';
export const UPDATE_DAYPART = 'UPDATE_DAYPART';


export const initialState = {
    organisationId: '',
    schoolId: '',
    absentId: '',
    groupsId: [],
    codeId: '',
    type: '',
    planning: '',
    label: '',

    isOpen: false,

    iHowToInformId: '',
    informEmailExtra1: [],
    informEmailExtra2: [],

    gym: [],
    substitutionNote: '',
    schedulerNote: '',
    dateRange: [],
}

export default function formReducer (prevState = initialState, {type, payload}) {
    switch (type) {
        case UPDATE_SUBSTITUTION_FORMS:
            return { ...prevState,  [payload.key]: payload.value }
        case RESET_SUBSTITUTION_FORMS:
            return initialState

        case ADD_DATE:
            return { ...prevState, dateRange: [...prevState.dateRange, payload] }
        case REMOVE_DATE:
            return { ...prevState, dateRange: prevState.dateRange.filter(sub => sub.date !== payload) }
        case UPDATE_DAYPART:
            return { ...prevState, dateRange:
                prevState.dateRange.map(e => {
                    return e.date === payload.date ?
                        Object.assign(e, {dayPart: payload.dayPart}) : e
                }) }
        default:
            return prevState
    }
};
