import ReactTableGeneric from "Components/ReactTableGeneric";
import { Fragment } from "react";
import { Card, CardBody } from "reactstrap";
import WeekReportInfo from "./WeekReportInfo";
import WeekReportTable from "./WeekReportTable";


const WeekReportTables = ({ data, columns }) => {
    if (!data) return null;
    return (
        <Fragment>
            <Card className="main-card mb-3">
                <Fragment>
                    {
                        data.info && data.info.length !== 0 &&
                        <CardBody className="p-3 border-dark">
                            <WeekReportInfo data={data.info} />
                        </CardBody>
                    }

                    {
                        data.prolonged && data.prolonged.length !== 0 &&
                        <CardBody className="p-3">
                            <ReactTableGeneric
                                data={data.prolonged}
                                columns={columns}
                                tableClassName="mb-0 table table-sm table-hover mt-3"
                                filters={{ search: false, pagination: false, columnSorting: true }}
                            />
                        </CardBody>
                    }

                    {
                        data.converted && data.converted.length !== 0 &&
                        <CardBody className="p-3 border-top border-dark subs-main-table">
                            <WeekReportTable reportData={Object.values(data.converted)} />
                        </CardBody>
                    }
                </Fragment>
            </Card>
        </Fragment>
    )
}

export default WeekReportTables;
