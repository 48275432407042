import React, { Fragment, useEffect, useState } from "react";
import {useNavigate, useParams} from "react-router-dom";
import axiosInstance from "Api";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { BsCheckCircle } from "react-icons/bs";
import { BsQuestionCircle } from "react-icons/bs";
import { TiDeleteOutline } from "react-icons/ti";
import {ReactComponent as Save} from "../../../../../assets/utils/images/svgs/save_two.svg";
import { ReactComponent as Multiplication} from "../../../../../assets/utils/images/svgs/Frame_two.svg";

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Col,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavLink,
    Row,
    UncontrolledButtonDropdown,
    Input,
} from "reactstrap";
// import {IoIosGrid} as Ionicons from "react-icons/io";
import VervangerDetails from "Pages/Vervanger/Home/Show/Partials/VervangerDetails";
import VervangerTable from "Pages/Vervanger/Home/Show/Partials/VervangerTable";
import PageHeading from "Layout/AppMain/PageHeading";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state 
    return () => setValue(value => value + 1); // update state to force render 
}

const Default = (props) => {

    const params = useParams();
    const [substitutionDetails, setSubstitutionDetails] = useState(null);
    const [substitutionDays, setSubstitutionDays] = useState([]);
    const [jsonData, setJsonData] = useState([]);
    const [value, setValue] = useState(0);
    const [show, setShow] = useState(false);
    const [isActivePeriode, setIsActivePeriode] = useState('unchecked');
    const [isFilter, setIsFilter] = useState(0);
    var i = 2;
    let navigate = useNavigate();

    const notify = (message) => {
        toast(message);
    }

    const subId = params.id || '';

    let days = jsonData;

    useEffect(() => {

    }, [value]);

    useEffect(() => {
        // getSubsitutionDetails()
        filterData();
    }, [isFilter]);
    const filterData = () => {
        console.log(isFilter);
        switch(isFilter) {
            case 1:
                openRequest();
                break;
            case 2:
                allRequest();
                break;
            case 3:
                getSubsitutionDetails();
                break;
            // case 4:
            //     onlyCurrentRequestId();
            //     break;
            default:
                currentRequest();
                
        }
        
    }

    const changeStatus = (dayId, status, statusOfAll) => {
        var newDays = substitutionDays;
        if (statusOfAll === 'acceptAll') {
            Object.keys(newDays).map(function (key, index) {
                Object.keys(substitutionDays[key]).map(function (day) {
                    if (newDays[key][day].Ochtend && newDays[key][day].Ochtend.status == "Aangevraagd" && newDays[key][day].Ochtend.substitutionId == subId) {
                        newDays[key][day].Ochtend.status = status;
                        days.push({ id: newDays[key][day].Ochtend.id, eSubStatus: status });
                    }
                    if (newDays[key][day].Middag && newDays[key][day].Middag.status == "Aangevraagd" && newDays[key][day].Middag.substitutionId == subId) {
                        newDays[key][day].Middag.status = status
                        days.push({ id: newDays[key][day].Middag.id, eSubStatus: status });
                    }
                })

            })
            isOpen();
        } else {
            removeDay({ id: dayId, eSubStatus: status });

            days.map((value, index) => {
                if (value.id === dayId) {
                    days.splice(index, 1);
                }
            })
            days.push({ id: dayId, eSubStatus: status });
            Object.keys(newDays).map(function (key, index) {
                Object.keys(substitutionDays[key]).map(function (day) {
                    if (newDays[key][day].Ochtend && newDays[key][day].Ochtend.id == dayId) {
                        newDays[key][day].Ochtend.status = status
                    }
                    else {
                        if (newDays[key][day].Middag && newDays[key][day].Middag.id == dayId)
                            newDays[key][day].Middag.status = status
                    }
                })
            })
        }
        setSubstitutionDays(newDays);
        setJsonData(days);
        setValue(value => value + 1);
    }

    const removeDay = (day) => {
        jsonData.map((value, index) => {
            if (value.id === day.id) {
                jsonData.splice(index, 1);
            }
        })
    }

    function currentRequest() {
        axiosInstance.get('substitutor/current-request/' + subId).then((response) => {
            if (response.status == 200) {
                if (response.data.data.id !== undefined) {
                    console.log(response.data.data);
                    setSubstitutionDetails(response.data);
                    setSubstitutionDays(response.data.data.substitutionDays);
                } else {
                    setSubstitutionDays([]);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    function onlyCurrentRequestId() {
        axiosInstance.get('substitutor/current-substitutionId/' + subId).then((response) => {
            if (response.status == 200) {
                if (response.data.data.id !== undefined) {
                    console.log(response.data.data);
                    setSubstitutionDetails(response.data);
                    setSubstitutionDays(response.data.data.substitutionDays);
                } else {
                    setSubstitutionDays([]);
                }
            }
        }).catch((error) => console.log("catch error:", error));
    }

    function openRequest() {
        if (substitutionDetails) {
            axiosInstance.get('substitutor/open-request/' + subId).then((response) => {
                if (response.status == 200) {
                   
                    if (response.data.data.id !== undefined) {
                        setSubstitutionDetails(response.data);
                        setSubstitutionDays(response.data.data.substitutionDays);
                    } else {
                        setSubstitutionDays([]);
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }

    const filterRequest = (filter) => {
        if(filter == 1 && isFilter == 1 ){
            setIsFilter(0);
        }else if(filter == 2 && isFilter == 2 ){
            setIsFilter(4);
        }else if(filter == 3 && isFilter == 3 ){
            setIsFilter(0);
        }else{
            setIsFilter(filter);
        }
    }

    const allRequest = () => {
        if (substitutionDetails) {
            axiosInstance.get('substitutor/all-request/' + subId).then((response) => {
                if (response.status == 200) {
                    if (response.data.data.id !== undefined) {
                        setSubstitutionDays(response.data.data.substitutionDays);
                    } else {
                        setSubstitutionDays([]);
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }

    function updateStatus() {
        axiosInstance.post('substitutor/substitution/' + subId + '/dayparts', jsonData).then((response) => {
            if (response.status === 200) {
                toast["success"]("Status succesvol bijgewerkt");
            }
        }).catch((error) => console.log("catch error:", error));
    }

    const isOpen = () => {
        if (show === false) {
            setShow(true);
        } else {
            setShow(false);
        }
    }

    function getSubsitutionDetails() {
        if (substitutionDetails) {
            axiosInstance.get('substitutor/full-period/' + subId).then((response) => {
                if (response.status == 200) {
                    if (response.data.data.id !== undefined) {
                        setSubstitutionDetails(response.data);
                        if (response.data.data.substitutionDays !== undefined) {
                            setSubstitutionDays(response.data.data.substitutionDays);
                        }
                    }
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }

    if (!substitutionDetails) return <Card><CardBody><div class="text-center">Geen data beschikbaar</div></CardBody></Card>;
    return (

        <Fragment>
            <TransitionGroup>
                <CSSTransition component="div" className="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <div className="chat-layout-wrapper">
                            <div className="app-inner-layout chat-layout" >
                                <PageHeading />
                                <div className="app-inner-layout__wrapper">
                                    <Card className="app-inner-layout__content">
                                        <Row>
                                            <Col md="12">

                                                <VervangerDetails substitutionDetails={substitutionDetails} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="12">
                                                <VervangerTable substitutionDays={substitutionDays} id={substitutionDetails?.data.id} changeStatus={changeStatus} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </div>
                                <div className="chat-layout-footer">
                                    <CardFooter className="d-block text-center d-flex chat-layout-footer__inner align-items-center">
                                        <div className="flex-grow-1 d-flex justify-content-start gap-2 align-items-center">
                                        <Input
                                            type="checkbox"
                                            onClick={() => filterRequest(1)}
                                            checked={ isFilter == 1 ? true : false }
                                        /> <span className="filter-text-style"> Alleen aangevraagde dagdelen </span>
                                        <Input
                                            type="checkbox"
                                            onClick={() => filterRequest(2)}
                                            checked={ isFilter == 2 ? true : false }
                                        /> <span className="filter-text-style"> Alle dagdelen </span>
                                        <Input
                                            type="checkbox"
                                            onClick={() => filterRequest(3)}
                                            checked={ isFilter == 3 ? true : false }
                                        /> <span className="filter-text-style"> Volledige periode </span>
                                            {/* <UncontrolledButtonDropdown >
                                                <DropdownToggle color="link" className="p-0">

                                                    <Button className=" btn-color-style mb-2 me-2 custom-btn-width" size="sm" outline color="primary">
                                                        Filter
                                                    </Button>
                                                </DropdownToggle>
                                                <DropdownMenu className="rm-pointers dropdown-menu-lg dropdown-width">

                                                    <Nav vertical>
                                                        <NavLink href="#" onClick={currentRequest}>
                                                            <span>Huidig verzoek</span>
                                                        </NavLink>

                                                        <NavLink href="#" className="navlink-border" onClick={openRequest}>
                                                            <span>Openstaand in huidig verzoek</span>
                                                        </NavLink>

                                                        <NavLink href="#" onClick={allRequest}>
                                                            <span>Alle dagdelen</span>
                                                        </NavLink>
                                                    </Nav>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown> */}
                                          
                                        </div>

                                        <div className={"svg-color-style"}>
                                        <UncontrolledButtonDropdown isOpen={show}>
                                                <DropdownToggle color="link" className="p-0">

                                                    <Button className="custom-btn-width btn-color-style  me-1" size="sm" outline color="primary" onClick={() => isOpen()} >
                                                        Wijzig alle dagdelen
                                                    </Button>
                                                </DropdownToggle>
                                                <DropdownMenu className={"rm-pointers dropdown-menu-lg  dropdown-width"} >

                                                    <Nav vertical>
                                                        <NavLink className="link-gap" onClick={() => changeStatus(
                                                            substitutionDays, 'Ingepland', 'acceptAll'
                                                        )}>
                                                            <span><BsCheckCircle color='#39A825' font-size={"21px"} />  </span>
                                                            <span>Accepteer alle dagdelen</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap navlink-border" >
                                                            <span><BsQuestionCircle color='#F2983E' font-size={"21px"} />  </span>
                                                            <span>Nog geen keuze gemaakt</span>
                                                        </NavLink>

                                                        <NavLink className="link-gap" onClick={() => changeStatus(
                                                            substitutionDays, 'Geannuleerd', 'acceptAll'
                                                        )}>
                                                            <span><TiDeleteOutline color='#FB2E5F' font-size={"28px"} /> </span>
                                                            <span style={{ marginLeft: '-5px' }}>Weiger alle dagdelen</span>
                                                        </NavLink>
                                                    </Nav>
                                                </DropdownMenu>
                                            </UncontrolledButtonDropdown>

                                            <Button size="sm" outline className="opslaan-btn-style me-1 btn-color-style" color="primary" onClick={updateStatus} >
                                                <span style={{marginRight:'5px' }}><Save/></span>
                                                Opslaan
                                            </Button>

                                            <Button size="sm" outline className="opslaan-btn-style me-1 btn-color-style" color="primary" onClick={() => navigate('/home')}>
                                                <span style={{marginRight:'5px'}} ><Multiplication/></span>
                                                Sluiten
                                            </Button>
                                        </div>
                                    </CardFooter>
                                </div>
                            </div>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <ToastContainer style={{ top: "70px" }} />
        </Fragment>
    )
}


export default Default;
