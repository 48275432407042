import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useState, useEffect, useRef } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, Col, Row, CardFooter, Button, Input, Label, ButtonToolbar } from "reactstrap";
import VerzoekenSchemaTable from "./table";
import axiosInstance from "Api";
import Multiselect from 'multiselect-react-dropdown';
import AppFooter from "Layout/AppFooter";

const Default = () => {

    const [data, setData] = useState([]);
    const [countArray, setCountArray] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
    const [communityId, setCommunityId] = useState([]);
    const [schoolId, setSchoolId] = useState([]);
    const [schoolList, setSchoolList] = useState([]);
    const [groupId, setGroupId] = useState([]);
    const [limit, setLimit] = useState('');
    const [markeren, setMarkeren] = useState('');
    const [markerenColor, setMarkerenColor] = useState('all');
    const [status, setStatus] = useState(['Openstaand']);
    const [isFilter, setIsFilter] = useState(1);
    const [planning, setPlanning] = useState(1);
    const [showLoader, setShowLoader] = useState(true);
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [serachValue, setSerachValue] = useState('');
    const ref = useRef(null);
    const [priorityListLoader, setPriorityListLoader] = useState(false);
    
    const filterRequest = (statusValue, filter) => {
        const getStatus = [];
        var index = status.indexOf(statusValue);
        if (index >= 0) {
            status.splice(index, 1);
            setStatus(status)
            var formData = [];
            formData = status;
            // getStatus.push(...status, statusValue);

            // var index1 = getStatus.indexOf(statusValue);
            // status.splice(index + 1, 1);
        } else {
            getStatus.push(...status, statusValue);
            setStatus(getStatus)
            var formData = [];
            formData = getStatus;
        }

        if(filter == 1 && isFilter == 1 ){
            setIsFilter(0);
            filterRequestByStatus(formData);
        }else if(filter == 2 && isFilter == 2 ){
            setIsFilter(5);
            filterRequestByStatus(formData);
        }else if(filter == 3 && isFilter == 3 ){
            setIsFilter(0);
            filterRequestByStatus(formData);
        }else if(filter == 4 && isFilter == 4 ){
            setIsFilter(0);
            filterRequestByStatus(formData);
        }else{
            setIsFilter(filter);
            filterRequestByStatus(formData);
        }
    }

    const userTablePageSize = (val) => {
        setPageNumber(1);
        setPageSizeCount(val);
    }

    useEffect(() => {
        setShowLoader(true);
        let formData = new FormData();
        formData.append('status', status);
        formData.append('planning', planning);
        formData.append('iCommunityId', communityId);
        formData.append('iSchoolId', schoolId);
        formData.append('limit', limit);
        formData.append('markeren', markeren);
        formData.append('iGroupId', groupId);
        formData.append('pageSize', pageSizeCount)
        formData.append('pageNumber', pageNumber)
        formData.append('search', serachValue)
        formData.append('markerenColor', markerenColor);

        axiosInstance.post('/verzoeken/scherm', formData).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false);
                setData(response.data.data);
                setSchoolList(response.data.data.schoolList);
                setTotalPageCount(response.data.data.count)
            }
        }).catch(() => console.error);

    }, [communityId, schoolId, groupId, markeren, planning, pageSizeCount, serachValue, pageNumber, markerenColor]);

    const priorityList = (value) => {
        setPriorityListLoader(true);
        let formData = new FormData();
        formData.append('status', status);
        formData.append('planning', planning);
        formData.append('iCommunityId', communityId);
        formData.append('iSchoolId', schoolId);
        formData.append('limit', value);
        formData.append('markeren', markeren);
        formData.append('iGroupId', groupId);
        formData.append('pageSize', pageSizeCount)
        formData.append('pageNumber', pageNumber)
        formData.append('search', serachValue)
        formData.append('markerenColor', markerenColor);

        axiosInstance.post('/verzoeken/scherm', formData).then((response) => {
            if (response.data.status === true) {
                setPriorityListLoader(false);
                setData(response.data.data);
                setSchoolList(response.data.data.schoolList);
                setTotalPageCount(response.data.data.count)
            }
        }).catch(() => console.error);
    }

    const handleCallback = (value, type) => {
        setPageNumber(1);
        if (type === 'communityId') {
            setCommunityId(value);
        }
        if (type === 'schoolId') {
            setSchoolId(value);
        }
        if (type === 'groupId') {
            setGroupId(value);
        }
        if (type === 'limit') {
            setLimit(value);
            priorityList(value);
        }
        if (type === 'markeren') {
            setMarkeren(value);
        }
        if (type === 'planning') {
            setPlanning(value);
        }
        if (type === 'markerenColor') {
            setMarkerenColor(value);
        }

    }

    const removeCommunity = (value, type) => {
        setShowLoader(true);
        let formData = new FormData();
        formData.append('iCommunityId', value );
        axiosInstance.post('/verzoeken/scherm', formData).then((response) => {
            if (response.data.status === true) {

                setSchoolList(response.data.data.schoolList);

                const ids = [];
                const commonValues = response.data.data.schoolList.filter(value => schoolId.includes(value.iSchoolId));
    
                commonValues.map((key) =>
                    ids.push(key.iSchoolId)
                );
                setCommunityId(value);
                setSchoolId(ids);            
            }
        }).catch(() => console.error);
    }


    const filterRequestByStatus = (statusValue) => {
        setShowLoader(true);
        setPageNumber(1);
        let formData = new FormData();
        formData.append('status', statusValue);
        formData.append('planning', planning);
        formData.append('iCommunityId', communityId);
        formData.append('iSchoolId', schoolId);
        formData.append('limit', limit);
        formData.append('markeren', markeren);
        formData.append('iGroupId', groupId);
        formData.append('pageSize', pageSizeCount);
        formData.append('pageNumber', pageNumber);
        formData.append('search', serachValue);
        formData.append('markerenColor', markerenColor);

        axiosInstance.post('/verzoeken/scherm', formData).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false);
                setData(response.data.data);
                setSchoolList(response.data.data.schoolList);
                setTotalPageCount(response.data.data.count)
            }
        }).catch(() => console.error);
    }

    const paginate = (val) => {
        setShowLoader(true);
        setPageNumber(val);
    }

   

    const handleInputChange = (val) => {
        const delay = 1000; // 1 seconds
        
        const timeoutId = setTimeout(() => {
            setPageNumber(1);
            setSerachValue(val);
        }, delay);
        
        return () => {
            clearTimeout(timeoutId);
        };
    }

    const filterList = (val) => {
        handleInputChange(val);
    }

    return (
        <Fragment>
            <PageHeading />
            <TransitionGroup>
                <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                    timeout={1500} enter={false} exit={false}>
                    <div>
                        <Row>
                            <Col md="12" lg="12">
                                <Card className="main-card mb-3">
                                    <CardBody className="p-3 table-responsive">
                                        <VerzoekenSchemaTable
                                            data={data.planingList}
                                            priorityList={data?.priorityList}
                                            markerenList={data.markerenList} 
                                            showLoader={showLoader}
                                            priorityListLoader={priorityListLoader}
                                            totalPageCount={totalPageCount}
                                            pageNumber={pageNumber}
                                            pageSizeCount={pageSizeCount}
                                            userTablePageSize={userTablePageSize}
                                            setPageNumber={setPageNumber}
                                            paginate={paginate}
                                            filterList={filterList}
                                        />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <div className="chat-layout-footer verzoken-footer-height">
                            <CardFooter className="d-block d-flex chat-layout-footer__inner">
                                <div>
                                    <Row>
                                        <Col md="4">
                                            <div className="checbox-border">
                                                <Row  style={{marginTop:'-4px'}}>
                                                    <Col md="3">
                                                        <Row className="custom-checkbox">
                                                            <Label className="col-md-12">Openstaand({data.countList !== undefined && data.countList.Openstaand !== undefined ? data.countList.Openstaand : 0})</Label>
                                                            <Input type="checkbox" className="col-md-12" name="informSms" onClick={(event) => filterRequest('Openstaand', 1)} checked={ status.indexOf('Openstaand') >= 0 ? true : false }/>
                                                        </Row>
                                                    </Col>
                                                    <Col md="3">
                                                        <Row className="custom-checkbox">
                                                            <Label className="col-md-12">Geparkeerd({data.countList !== undefined && data.countList.Geparkeerd !== undefined ? data.countList.Geparkeerd : 0})</Label>
                                                            <Input type="checkbox" className="col-md-12" name="informSms" onClick={(event) => filterRequest('Geparkeerd', 2)} checked={ status.indexOf('Geparkeerd') >= 0 ? true : false }/>
                                                        </Row>
                                                    </Col>
                                                    <Col md="3">
                                                        <Row className="custom-checkbox">
                                                            <Label className="col-md-12">Behandeld</Label>
                                                            <Input type="checkbox" className="col-md-12" name="informSms" onClick={(event) => filterRequest('Behandeld', 3)} checked={ status.indexOf('Behandeld') >= 0 ? true : false }/>
                                                        </Row>
                                                    </Col>
                                                    <Col md="3">
                                                        <Row className="custom-checkbox">
                                                            <Label className="col-md-12">Historie</Label>
                                                            <Input type="checkbox" className="col-md-12" name="informSms" onClick={(event) => filterRequest('Afgesloten', 4)} checked={ status.indexOf('Afgesloten') >= 0 ? true : false }/>
                                                        </Row>
                                                    </Col>

                                                </Row>
                                            </div>
                                        </Col>
                                        <Col md="8">
                                            <div>
                                                <Row className={"typeHead-CustomStyle"}style={{marginTop:'-8px'}}>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>Organisatie</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                            <Multiselect
                                                                style={{color:'#878EA6' }}
                                                                className="custom-multiselector"
                                                                displayValue="name"
                                                                hideSelectedList
                                                                onRemove={e =>{
                                                                    removeCommunity(e.map(key => key.iCommunityId), 'communityId')
                                                                }}
                                                                onSearch={function noRefCheck(){}}
                                                                onSelect={e =>{
                                                                    setCommunityId(e.map(key => key.iCommunityId))
                                                                    handleCallback(e.map(key => key.iCommunityId), 'communityId')
                                                                }}
                                                                options={data.communityList !== undefined ? data.communityList : []}
                                                                placeholder="Alle"
                                                                showCheckbox
                                                            />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>School</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                                <Multiselect
                                                                    className="custom-multiselector"
                                                                    displayValue="vSchoolName"
                                                                    hideSelectedList
                                                                    onKeyPressFn={function noRefCheck(){}}
                                                                    onRemove={e =>{
                                                                        setSchoolId(e.map(key => key.iSchoolId))
                                                                        handleCallback(e.map(key => key.iSchoolId), 'schoolId')
                                                                    }}
                                                                    onSearch={function noRefCheck(){}}
                                                                    onSelect={e =>{
                                                                        setSchoolId(e.map(key => key.iSchoolId))
                                                                        handleCallback(e.map(key => key.iSchoolId), 'schoolId')
                                                                    }}
                                                                    options={schoolList}
                                                                    placeholder="Alle"
                                                                    showCheckbox
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>Functie</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                                <Multiselect
                                                                    style={{color:'#878EA6' }}
                                                                    className="custom-multiselector"
                                                                    displayValue="text"
                                                                    hideSelectedList
                                                                    onKeyPressFn={function noRefCheck(){}}
                                                                    
                                                                    onRemove={e => {
                                                                        setGroupId(e.map(key => key.iSubGroupId))
                                                                        handleCallback(e.map(key => key.iSubGroupId), 'groupId')
                                                                    }}
                                                                    onSearch={function noRefCheck(){}}
                                                                    onSelect={e => {
                                                                        setGroupId(e.map(key => key.iSubGroupId))
                                                                        handleCallback(e.map(key => key.iSubGroupId), 'groupId')
                                                                    }}
                                                                    placeholder="Alle"
                                                                    options={data.groupList !== undefined ? data.groupList : []}
                                                                    showCheckbox
                                                                />
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>Dagen</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                                <select className="form-select" style={{height:'28px'}}
                                                                    onChange={(event) => handleCallback(event.target.value, 'limit')}
                                                                >
                                                                    <option value=''>Nvt</option>
                                                                    {countArray.map((key) => (
                                                                        <option value={key}>{key}</option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>Planning</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                                <select className="form-select" style={{height:'28px'}}
                                                                    onChange={(event) => handleCallback(event.target.value, 'planning')}
                                                                >
                                                                    <option value=''>Alle</option>
                                                                    <option value='1' selected={planning == 1 ? true : false}>Centrale Planning</option>
                                                                    <option value='2' selected={planning == 2 ? true : false}>School Planning</option>
                                                                    
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                    <Col md="2">
                                                        <Row className="form-checkbox-styling">
                                                            <div className="filterBy col-md-6 p-0">
                                                                <label>Kleur</label>
                                                            </div>
                                                            <div className="col-md-10 selectBy p-0">
                                                                <select className="form-select" style={{ height: '28px' }}
                                                                    onChange={(event) => handleCallback(event.target.value, 'markerenColor')}
                                                                >
                                                                    <option value=''>Selecteer kleur</option>
                                                                    <option value='all'>Grijs</option>
                                                                    <option value='#FF0000'>Rood</option>
                                                                    <option value='#008000'>Groen</option>
                                                                    <option value='#800080'>Paars</option>
                                                                    <option value='#FFA500'>Oranje</option>
                                                                </select>
                                                            </div>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                            </CardFooter>
                        </div>
                    </div>
                </CSSTransition>
            </TransitionGroup>
            <AppFooter />
        </Fragment>
    )
};

export default Default;
