import PageHeading from "Layout/AppMain/PageHeading";
import React, { Fragment, useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Card, CardBody, CardFooter, CardHeader, Col, Row, Table, Button, Collapse } from "reactstrap";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import TextLimit from "Api/TextLimit";
// import VervangerTable from "Pages/TestView/Default/VervangerTable";


const VervangerDetails = ({ substitutionDetails}) => {
    const [slideMenuState, setSlideMenuState] = useState(false);
    const [collapseStatus, setCollapseStatus] = useState("Closed");

    const toggleCollapsed = () => {
        setSlideMenuState((current) => !current);
    }

    const onEntered = () => {
        setCollapseStatus("Opened");
    }

    const onExited = () => {
        setCollapseStatus("Closed");
    }

    return (
        <Fragment>
            <Card className="main-card mb-3 sub-detail-table-style">
                <CardBody className="p-3">
                    <Table size="sm" borderless className="mb-0 border-btm-none">
                        <tbody>
                            <tr>
                                <th className="fw-bold col-2">Verzoek</th>
                                <th className="fw-bold col-2">School</th>
                                <th className="fw-bold col-2">Afwezige</th>
                                <th className="fw-bold col-2">Functies</th>
                                <th className="fw-bold col-2">Ketenregeling</th>
                            </tr>
                            <tr>
                                <td className="wl">
                                    {substitutionDetails.data.id ?? ''}
                                </td>

                                <td className="wl">
                                    {
                                        TextLimit.limitStringLargeScreen(substitutionDetails.data.schoolName)
                                    }
                                </td>

                                <td className="wl">
                                {

                                        window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(substitutionDetails.data.vFirstName, substitutionDetails.data.vMiddleName, substitutionDetails.data.vLastName)
                                            :TextLimit.AfwezigeLimitOptionTwo(substitutionDetails.data.vFirstName, substitutionDetails.data.vMiddleName, substitutionDetails.data.vLastName)
                                    
                                }
                                </td>

                                <td className="wl">
                                    {substitutionDetails.data.jobTitles.toString() ?? ''}
                                </td>

                                <td className="wl">
                                    {
                                        substitutionDetails.data.vKostenplaats ?? ''
                                    }
                                </td>
                            </tr>


                            <tr>
                                <td className="p-0" colSpan="12">
                                    <Collapse isOpen={slideMenuState} onEntered={onEntered}
                                        onExited={onExited}>
                                        <Table size="sm" borderless className="mb-0">
                                            <tbody>
                                                <tr>
                                                    <th className="fw-bold">Adres</th>
                                                    <th className="fw-bold">Telefoon</th>
                                                    <th className="fw-bold">E-mail</th>
                                                    <th className="fw-bold">Lestijden</th>
                                                    <th className="fw-bold ">Website</th>
                                                </tr>
                                                <tr>
                                                    <td className="wl">
                                                        {substitutionDetails.data.address.street !== undefined && substitutionDetails.data.address.city !== undefined ? substitutionDetails.data.address.street + ', ' + substitutionDetails.data.address.city : ''}
                                                    </td>

                                                    <td className="wl">
                                                        {substitutionDetails.data.contact.business.phone !== undefined ? substitutionDetails.data.contact.business.phone : ''}
                                                    </td>

                                                    <td className="wl">
                                                        {substitutionDetails.data.contact.business.email !== undefined ? substitutionDetails.data.contact.business.email : ''}
                                                    </td>

                                                    <td className="wl">
                                                        {substitutionDetails.data.classTimes !== undefined ? substitutionDetails.data.classTimes : ''}
                                                    </td>

                                                    <td className="wl">
                                                        {substitutionDetails.data.website !== undefined ? substitutionDetails.data.website : ''}
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </Table>
                                        <tr>
                                            <td colSpan="12">
                                                <h4 className="fw-bold content-head-style">Opmerking verzoek</h4>
                                                <p className="content-body-style">
                                                    {substitutionDetails.data.vSubstitutorComments !== undefined && substitutionDetails.data.vSubstitutorComments !== null ? substitutionDetails.data.vSubstitutorComments : ''}
                                                </p>
                                            </td>
                                        </tr>
                                    </Collapse>
                                </td>
                            </tr>

                            <tr>
                                <td colSpan="4" className="text-center">
                                    <Button outline className="btn-xs btn-table-show btn-color-style"
                                        color="secondary" onClick={toggleCollapsed}>
                                        {collapseStatus === "Closed" ?
                                            <IoIosArrowDown fontSize="12px" color="secondary" /> :
                                            <IoIosArrowUp fontSize="12px" color="secondary" />}
                                        {collapseStatus === "Closed" ? " Meer" : " Minder"}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </CardBody>
            </Card>
            {/* <Row>
                <Col md="12">
                    <Card className="main-card mb-3">
                        <CardBody className="p-3">
                            <VervangerTable />

                            <div className="d-flex justify-content-between mt-4">

                                <div className="flex-grow-1 d-flex justify-content-start">

                                    <Button className="d-flex align-items-center btn-color-style mb-2 me-2" size="sm" outline color="primary">
                                        Filter
                                    </Button>

                                    <Button className="d-flex align-items-center btn-color-style mb-2 me-2" size="sm" outline color="primary">
                                        Wijzig alle dagdelen
                                    </Button>


                                </div>
                                <div className="d-flex justify-content-end">
                                    <Button size="sm" outline className="me-2 btn-color-style" color="primary" >
                                        Opslaan
                                    </Button>

                                    <Button size="sm" outline className="me-2 btn-color-style" color="primary" >
                                        Sluiten
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row> */}

        </Fragment>
    )
};

export default VervangerDetails;