import React, { Fragment, useEffect, useState } from "react";
import { Button, Card, CardBody, Collapse, Nav, NavItem, Popover, Table } from "reactstrap";
import Tippy from "@tippyjs/react";
import LongContractsRequest from './LongContractsRequest';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { BsCheckCircle } from "react-icons/bs";
import Historie from "../../../../../assets/utils/images/svgs/Historie.svg";
import { ReactComponent as EditPen } from "../../../../../assets/utils/images/svgs/editPen.svg";
import { ReactComponent as Message } from "../../../../../assets/utils/images/svgs/Message.svg";
import { ReactComponent as Phone } from "../../../../../assets/utils/images/svgs/Phone.svg";
import { ReactComponent as Informatie } from "../../../../../assets/utils/images/svgs/Informatie.svg";
import { ReactComponent as DetailsPageCalender } from "../../../../../assets/utils/images/svgs/DetailsPageCalender.svg";
import map from "lodash/map"
import TextLimit from "Api/TextLimit";
import ContactDetailsTable from "./ContactDetailsTable";

const Details = ({data, getRequests, getLongContractDetails, setType}) => {
    const [slideMenuState, setSlideMenuState] = useState(false);
    const [collapseStatus, setCollapseStatus] = useState("Closed");

    const onEntered = () => {
        setCollapseStatus("Opened");
    }

    const onExited = () => {
        setCollapseStatus("Closed");
    }

    const toggleCollapsed = () => {
        setSlideMenuState((current) => !current);
    }

    const onHover = (data, content) => (<Tippy content={content ?? data} placement="bottom"><span>{data}</span></Tippy>)

    const iconSwitch = (param) => {
        switch (param) {
            case 'Openstaand':
                return <Tippy content={param} placement="bottom"><span><HiOutlineDotsCircleHorizontal fontSize={"26px"} /></span></Tippy>;
            case 'Afgesloten':
                return <Tippy content={param} placement="bottom"><span><img src={Historie} width="25" /></span></Tippy >;
            case 'Behandeld':
                return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"22px"} /></span></Tippy>;
            default:
                return '--';
        }
    }

    if (!data) return null;

    return (
        <Fragment>
            <Card className="main-card mb-3 sub-detail-table-style">
                <CardBody className="p-3">
                    <Table size="sm" borderless className="mb-0 ">
                        <tbody>
                            <tr className="border-bottom-0">
                                <th className="fw-bold col-2">ID/ Planning</th>
                                <th className="fw-bold col-2">School</th>
                                <th className="fw-bold col-2">Datum en tijd </th>
                                <th className="fw-bold col-2">Code</th>
                                <th className="fw-bold col-2">Functies</th>
                            </tr>
                            <tr className="border-bottom-0">
                                <td className="wl">
                                    {(data.iSubstitutionId ? data.iSubstitutionId : '') + " / " + (data.iPlanOption === 3 || data.iPlanOption === 0 ? 'School planning' : 'Centrale planning')}
                                </td>

                                <td className="wl">
                                    {data.commmunityName ? data.commmunityName + '-' + data.schoolName + ' (' + data.visitcity + ')' : '----'}
                                </td>

                                <td className="wl">
                                    {data.dAddedByDir ? data.dAddedByDir : '----'}
                                </td>

                                <td className="wl">
                                    <Tippy content={data.code?.vCodeValue} placement="bottom"><a href="#"> {TextLimit.codeLimitOptionTwo(data.code.vCode, data.code?.vCodeValue) ?? "----"} </a></Tippy>
                                </td>

                                <td className="wl">
                                    {data.group ? data.group : '----'}
                                </td>
                            </tr>

                            <tr className="border-bottom-0">
                                <td className="p-0" colSpan="5">
                                    <Collapse isOpen={slideMenuState} onEntered={onEntered}
                                        onExited={onExited}>
                                        <Table size="sm" borderless className="mb-0 verzoeken-inner-table">
                                            <tbody>
                                                <tr className="border-bottom-0">
                                                    <th className="fw-bold col-2">Melder</th>
                                                    <th className="fw-bold col-2">Afwezige</th>
                                                    {/* <th className="fw-bold col-2">Datum en tijd</th> */}
                                                    <th className="fw-bold col-2">Kostenplaats/Project</th>
                                                    <th className="fw-bold col-2">Ketenregeling toepassen</th>
                                                    <th className="fw-bold col-2"></th>
                                                    {/* <th className="fw-bold col-2">Contact info</th> */}
                                                    {/* <th className="fw-bold col-2" ></th> */}
                                                </tr>
                                                <tr className="border-bottom-0">
                                                    <td className="wl">
                                                    {window.innerWidth > 1250 ? TextLimit.AfwezigeLimitOptionOne(data.firstName, data.middleName, data.lastName)
                                                        :TextLimit.AfwezigeLimitOptionTwo(data.firstName, data.middleName, data.lastName)}
                                                    </td>

                                                    <td className="wl">
                                                        {onHover(data.absent !== '' && data.absent !== null ? TextLimit.AfwezigeLimitOptionOne(data.absent.vFirstName, data.absent.vMiddleName, data.absent.vLastName) : "----")}
                                                    </td>
{/* 
                                                    <td className="wl">
                                                        {data.dAddedByDir ? data.dAddedByDir : '----'}
                                                    </td> */}

                                                    <td className="wl">
                                                        {data.vKostenplaats ? data.vKostenplaats : '----'}
                                                    </td>

                                                    {/* <td className="wl">
                                                        {data.vBusinessEmail ? data.vBusinessEmail : '----'}
                                                    </td> */}

                                                    {/* <td className="wl">
                                                        <span className="me-3"><EditPen/></span>
                                                        <span className="me-3"><Message/></span>
                                                    </td> */}

                                                    <td className="wl">
                                                        {data.eExcludeWwz ? (data.eExcludeWwz === '0' ? 'JA' : 'NEE' ) : '----'}
                                                    </td>
                                                    <td className="wl">
                                                        
                                                    </td>

                                                </tr>

                                                <tr className="border-bottom-0">
                                                    {/* <th className="fw-bold col-2">Contact historie</th> */}
                                                    
                                                </tr>
                                                <tr className="border-bottom-0">
                                                    {/* <td className="wl">
                                                        <span className="me-3"><Message/></span>
                                                        <span className="me-3"><Phone/></span>
                                                        <span className="me-3"><Informatie/></span>
                                                        <span className="me-3"><DetailsPageCalender/></span>
                                                    </td> */}
                                                   
                                                </tr>
                                            </tbody>
                                        </Table>
                                        <div className="card-header long-contract-request-header">Contactgegevens</div>
                                            <ContactDetailsTable data={data} getRequests={getRequests} />
                                    </Collapse>
                                </td>
                            </tr>
            

                            <tr className="border-bottom-0">
                                <td colSpan="4" className="text-center">
                                    <Button outline className="btn-xs btn-table-show btn-color-style"
                                        color="secondary" onClick={toggleCollapsed}>
                                        {collapseStatus === "Closed" ?
                                            <IoIosArrowDown fontSize="12px" color="secondary" /> :
                                            <IoIosArrowUp fontSize="12px" color="secondary" />}
                                        {collapseStatus === "Closed" ? " Meer" : " Minder"}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                   
                </CardBody>
            </Card>

            

            {data?.longContractList.length > 0 && 
                <Card className="mb-3">
                    <CardBody>
                    <div className="card-header long-contract-request-header">Lange contracten</div>
                        <LongContractsRequest list={data?.longContractList} getRequests={getRequests} getLongContractDetails={getLongContractDetails} setType={setType}/>
                    </CardBody>
                </Card>
            }
           
        </Fragment>
    )
};

export default Details;
