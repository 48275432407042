import React, { Fragment, useEffect, useState } from "react";
import { Col, Label, Row } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import TextareaAutosize from "react-textarea-autosize";
import Calender from "Components/Calendar";
import dayjs from 'dayjs';
import { ErrorMessage } from "formik";

// change language (for months names)
require('dayjs/locale/nl');
dayjs.locale('nl');


const weekDaysListWOWeekend = ['Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag']

const Calendar = ({ formikProps, formDataState, setFormDataState }) => {
    const charactersPerInput = 150;

    const [formState, setFormState] = useState({dateRange: []});
    const { setFieldValue, errors } = formikProps;

    // save dateRange in formik state 
    useEffect(() => {setFieldValue('dateRange', formState.dateRange)}, [formState]);

    const manageDays = (daysElemArray) =>{
       
    }

    const manageDate = (day) => {
        
    }

    const removeDate = (day) => {
       
    }

    const gym = [];

    formDataState.dateRange.map(day => {
      
        let dayId = [];
        let sift = [];
        let weekDayNum = dayjs(day.date).day();
        if (day.dayPart ===  "ochtend") {
            dayId = [weekDayNum * 2 - 1]
            sift = [' ochtend'];
        } else if (day.dayPart ===  "middag") {
            dayId = [weekDayNum * 2]
            sift = [' middag']
        } else {
            dayId = [weekDayNum * 2 - 1, weekDayNum * 2]
            sift = [' ochtend', ' middag']
        }
        let gymObj = sift.map((dayPart, dayPartId) => {
            return {
                id: dayId[dayPartId],
                day: weekDaysListWOWeekend[weekDayNum - 1] + dayPart,
            }
        });

        !gym.some(obj => obj.day == gymObj[0].day) && gym.push(...gymObj);
        gym.sort((x, y) => x.id - y.id);

    })


    return (
        <Fragment>
            <Calender data={formDataState} prevState={formState} setState={setFormState} weekDays={weekDaysListWOWeekend} manageDays={manageDays} manageDate={manageDate} removeDate={removeDate} setDatesState={setFormDataState} isDisabled={errors.dateRange} />
            <Row className="calendar-fields-container">
                <Col className="mt-5">
                    <Label>Gym</Label>
                    <Typeahead
                        id="gym"
                        name="gym"
                        labelKey="day"
                        options={gym}
                        multiple={true}
                        placeholder="Kies gym dagen"
                        className="d-flex" style={{ flex: "1 0 185px" }}
                        defaultSelected={formDataState.gym}
                        onChange={gym => {
                            setFieldValue('gym', gym.sort((x, y) => x.id - y.id).map(e => e.id)) //sort gym data by id
                            setFormDataState({ ...formDataState, gym })
                        }}
                    />
                </Col>
                <Col className="mt-5">
                    <Label>Opmerking aan vervanger</Label>
                    <TextareaAutosize
                        name="substitutionNote"
                        className="form-control"
                        maxLength={charactersPerInput}
                        maxRows={5}
                        value={formDataState.substitutionNote}
                        onChange={e => {
                            setFieldValue('substitutionNote', e.target.value)
                            setFormDataState({ ...formDataState, substitutionNote: e.target.value })
                        }}
                    />
                </Col>
                <Col className="mt-5">
                    <Label>Opmerking aan planner</Label>
                    <TextareaAutosize
                        name="schedulerNote"
                        className="form-control"
                        maxLength={charactersPerInput}
                        maxRows={5}
                        value={formDataState.schedulerNote}
                        onChange={e => {
                            setFieldValue('schedulerNote', e.target.value)
                            setFormDataState({ ...formDataState, schedulerNote: e.target.value })
                        }}
                    />
                </Col>
            </Row>
        </Fragment>
    )
}

export default Calendar;
