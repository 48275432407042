import { React, useState, useEffect } from "react";
import ReactTableGeneric from "Components/ReactTableGeneric";
import DocumentService from "Api/Document";

const Record = (props) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(props.data);
  }, [data]);

  const columns =  [
        {
            header: () => 'Document',
            accessorKey: 'vLabel',
        },
        {
            header: () => 'Uploaddatum',
            accessorKey: 'vUpload',
        },
        {
            header: () => 'Verloopdatum',
            accessorKey: 'vExpired',
        },
        {
            header: () => 'Actie',
            accessorKey: 'file',
            cell: value => ( <a href="javascript:void(0)" onClick={()=>downloadFile(value.row.original.id)} className="anchor-action-link">
            <div className="font-icon-wrapper download-button-style"><i className="pe-7s-download btn-icon-wrapper"> </i></div>
        </a> ),
              
        },
    ];

    function downloadFile(id) {
      DocumentService.downloadDocument(id).then((response) => {
        if( response.data.file.download !== ''){
          const linkSource = response.data.file.download;
          const downloadLink = document.createElement("a");
          const fileName = "document."+response.data.file.extension;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }).catch(() => console.error);
    }

    return (
      <>
      { 
      data.length > 0 ? (
        <ReactTableGeneric data={data} tableClassName="mb-0 table table-sm table-hover"
                                columns={columns} filters={{search: false, pagination: false, sorting: true}}
        />
      ) :
      (
        <div class="text-center">Geen documenten beschikbaar</div>
      )
      }
      </>
    );
};
export default Record;
