import React, {Fragment} from "react";

// Components
import Default from "./Partials";
import AppFooter from "Layout/AppFooter";

import AppHeader from "../../../../Layout/AppHeader";


// Components
import ThemeOptions from "../../../../Layout/ThemeOptions";

const Show = ({match}) => {
    return (
        <Fragment>
            <AppHeader/>
            <ThemeOptions/>
                <div className="app-main__inner">
                    <Default/>
                </div>
            <AppFooter/>
        </Fragment>
    )
};

export default Show;