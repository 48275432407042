import React, {Fragment, useEffect, useState} from "react";
import LoggingReactTable from "Components/LoggingReactTable";
import 'react-datepicker/dist/react-datepicker.css';
import 'tippy.js/dist/tippy.css';
import moment from 'moment';
import axiosInstance from "Api";
import Loader from "react-loaders";

const List = (props) => {

    const [data, setData] = useState([]);
    const [pageSizeCount, setPageSizeCount] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(0);
    const [serachValue, setSerachValue] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const paginate = (val) => {
        setShowLoader(true);
        setPageNumber(val);
        axiosInstance.get('/logging?pageSize=' + pageSizeCount + '&page=' + val + '&search=' + serachValue).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false);
                setData(response.data.list);
            }
        }).catch(() => console.error);
    }

    useEffect(() => {
        if (data.length === 0) {
            axiosInstance.get('/logging?pageSize=' + pageSizeCount + '&page=' + pageNumber + '&search=' + serachValue).then((response) => {
                if (response.data.status === true) {
                    setData(response.data.list);
                    setTotalPageCount(response.data.pageCount);
                }
            }).catch(() => console.error);
        }
    }, [data]);

    useEffect(() => {
        const delay = 1000; // 1 seconds
        
        const timeoutId = setTimeout(() => {
            axiosInstance.get('/logging?pageSize=' + pageSizeCount + '&page=' + pageNumber + '&search=' + serachValue).then((response) => {
                if (response.data.status === true) {
                    setShowLoader(false);
                    setData(response.data.list);
                    setTotalPageCount(response.data.pageCount);
                }
            }).catch(() => console.error);
        }, delay);
    
        return () => {
          clearTimeout(timeoutId);
        };
    }, [serachValue]);

    const handleInputChange = (val) => {
        setShowLoader(true);
        setPageNumber(1);
        setSerachValue(val);
    };

    const filterList = (val) => {
        handleInputChange(val);
    }

    const indexPageSize = (val) => {
        console.log('pageNumber', pageNumber);
        setShowLoader(true);
        setPageSizeCount(val);
        axiosInstance.get('/logging?pageSize=' + val + '&page=' + '1' + '&search=' + serachValue).then((response) => {
            if (response.data.status === true) {
                setShowLoader(false);
                setData(response.data.list);
                setTotalPageCount(response.data.pageCount);
            }
        }).catch(() => console.error);
    }

    const loggingTablePageSize = (val) => {
        setShowLoader(true);
        setPageNumber(1);
        indexPageSize(val);
    }
    
    // const data = React.useMemo(
    //     () => props.data,
    //     []
    // );
    
    // const sortedData = data?.map(obj => { return { ...obj, date: new Date(obj.iTimestamp)}})
    // .sort((a, b) => b.date - a.date);


    const columns = [
            {
                header: 'Username',
                accessorKey: 'vUserName', // accessor is the "key" in the data
                cell: value => <span style={{textTransform:"capitalize"}}>{value.row.original.vUserName}</span>
            },
            {
                header: 'Role',
                accessorKey: 'role', // accessor is the "key" in the data
            },
            {
                header: 'Activity Type',
                accessorKey: 'activityType',
            },
            {
                header: 'Date Time',
                accessorKey: 'iTimestamp',
                cell: value => <span>{moment(value.row.original.iTimestamp).format("DD-MM-YYYY HH:mm")}</span>
            },
            {
                header: 'Message',
                accessorKey: 'data'
            }
        ];

    return (
        <>
                <LoggingReactTable
                data={data}
                columns={columns}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                totalPageCount={totalPageCount}
                paginate={paginate}
                filterList={filterList}
                loggingTablePageSize={loggingTablePageSize}
                pageSizeCount={pageSizeCount}
                showLoader={showLoader}
                tableClassName="mb-0 table table-borderless table-sm table-hover table-heading-style"
            />
        </>
    )
}


export default List;
