import React, {useState} from 'react';
import {Field, Form, Formik} from "formik";
// import FormikInputGroup from "../common/FormikInputGroup";
import * as Yup from 'yup';

import {Button, Col, Container, FormGroup, Label, Row} from "reactstrap";
import BootstrapInputGroup from "Pages/Profile/Forms/BootstrapInputGroup";
import axiosInstance from "Api";
import FormStatus from "Pages/Profile/Forms/FormStatus";
import DefaultButton from 'Components/Buttons/DefaultButton';

const DisplayingErrorMessagesSchema = Yup.object().shape({
    password_current: Yup.string().trim()
        .required('Veld mag niet leeg zijn').test('Password Correct', 'Wachtwoord komt niet overeen met uw huidige wachtwoord',
            function (value) {
                if (value && value.length >= 8) {
                    return new Promise((resolve, reject) => {
                        axiosInstance.post('users/validate', {'password_current': value}).then(res => {
                            if (res.data.status === 'success') {
                                resolve(true);
                            }
                            resolve(false);
                        })
                    })
                }
            }
        ),
    password: Yup.string()
        .matches("^\\S*$", "Wachtwoord mag geen spaties hebben")
        .required('Wachtwoord is verplicht')
        .min(8, "Wachtwoord moet minimaal 8 tekens lang zijn")
        .matches("[A-Za-z]+", "Gebruik hoofdletters en kleine letters a-z en A-Z tekens")
        .matches("[0-9]+", "Wachtwoord moet cijfers bevatten")
        .matches("[`!@#$%^&*()_+\\-=\\[\\]{};':\"\\\\|,.<>\\/?~]+", "Wachtwoord moet speciale tekens bevatten !@#$%^&*()_+"),
    password_confirmation: Yup.string().required('Wachtwoordbevestiging is vereist')
        .oneOf([Yup.ref('password'), null], 'Wachtwoorden moeten overeenkomen')
});


const ChangePasswordForm = (props) => {
    const [message, setMessage] = useState({type: null, text: ''});

    const requestUrl = props.url ?? 'users/change-password';
    const errorStatus = true;

    return (
        <Formik
            initialValues={{password_current: '', password: '', password_confirmation: ''}}
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values, {setSubmitting, setErrors, resetForm}) => {
                setTimeout(() => {
                    axiosInstance.post(requestUrl, values).then(response => {
                        if (response.data.status === "success") {
                            setMessage({type: 'success', text: 'Wachtwoord succesvol aangepast'})
                            resetForm();
                        }
                    }).catch(err => {
                        if (err.response) {
                            if (err.response.status === 422) {
                                let errors = {};
                                for (let key in err.response.data.errors) {
                                    errors[key] = err.response.data.errors[key][0];
                                }
                                setErrors(errors);
                            }
                        }
                    }).finally(() => {
                            setSubmitting(false);
                        }
                    );

                }, 500)
            }}
        >
            {(formik) => (
                <Form>
                    {< Container>
                        {message.type && <FormStatus message={message} status="success" changeMessage={setMessage}/>}
                        <Row>
                            <Col sm="6">
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="password_current">
                                                Je huidige wachtwoord
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Field name="password_current">
                                                {({field, form, meta}) => (
                                                    <BootstrapInputGroup field={field} meta={meta}/>
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="password">
                                                Kies een nieuwe wachtwoord
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Field name="password">
                                                {({field, form, meta}) => (
                                                    <BootstrapInputGroup field={field} meta={meta}/>
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="password_confirmation">
                                                Bevestig je nieuwe wachtwoord
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Field name="password_confirmation">
                                                {({field, form, meta}) => (
                                                    <BootstrapInputGroup field={field} meta={meta}/>
                                                )}
                                            </Field>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={{size: 6, offset: 6}} className=" px-2">
                                        <DefaultButton type="submit" disabled={formik.isValid ? false : true}
                                                className="mb-2 me-0 btn-color-style" color="primary">
                                            Aanpassen
                                        </DefaultButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>}
                </Form>
            )}
        </Formik>
    )
}

export default ChangePasswordForm;
