import React from "react";

const DebouncedInput = ({initialValue,
                            onChange,
                            debounce = 500,
                            ...props
                        }) => {
    const [value, setValue] = React.useState(initialValue )

    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            // console.log(value, "debounced");
            onChange(value || '')
        }, debounce)

        return () => clearTimeout(timeout)
    }, [value])

    return (
        <input {...props} value={value || ''} onChange={e => setValue(e.target.value)} />
    )
}

export default DebouncedInput;