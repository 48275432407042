import {useFloating} from '@floating-ui/react-dom';
import { Card, CardBody, Table } from "reactstrap";
import { useState } from "react";


const UserInfoPopup = ({ name, columns }) => {
    const [isOpen, setIsOpen] = useState(false);
    const {x, y, reference, floating} = useFloating();
 
    return (
        <>{isOpen && columns && (
            <div
                ref={floating}
                style={{
                    position: 'absolute',
                    top: y + 10 ?? 0,
                    left: x ?? 0,
                    width: 'max-content',
                    zIndex: 10,
                }}>
            <Card className="bg-white rounded">
                <CardBody className="d-flex">
                    <Table borderless className="mb-0 border-btm-none">
                    <tbody>
                            {columns && columns.map(({title, value}) =>
                                <tr key={value}>
                                    <th className="py-0" scope="row">{title}</th>
                                    <td className="d-block p-0 mx-4">{value}</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                    {/* <i className="nav-link-icon lnr-cross m-1 cursor-pointer" onClick={() => setIsOpen(false)}> </i> */}
                </CardBody>
            </Card>
            </div>
        )}

            <span 
                ref={reference} 
                className="cursor-pointer"
                onMouseOver={() => setIsOpen(true)} 
                onMouseLeave={() => setIsOpen(false)} 
            >{name}
            </span>
        </>
    )

}

export default UserInfoPopup;
