import React, { Component, Fragment, useEffect, useState } from "react";

const CustomFilter = ({ data, handleCallback }) => {

    return (
        <Fragment>
            <div>
                <select className="form-select custom-Filter-dropdown" onChange={(event) => handleCallback(event.target.value)}>
                    <option value="">Alle scholen</option>
                    {data.map((school, index) => (
                        <option key={index} value={school.filterId}>{school.filterName}</option>
                    ))}
                </select>
            </div>
        </Fragment>
    )
};

export default CustomFilter;