import React from "react";


const DefaultButton = ({className, type, ...props}) => {

    return (
        <button className={ "btn btn-outline-secondary " + (className ?? '')}
                type= {type ?? "button"}
                {...props}
                aria-expanded="false">
            {props.children}
        </button>
    );
};

export default DefaultButton;