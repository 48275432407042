import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Row,
} from "reactstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Record from "./table";
import DocumentService from "Api/Document";
import axiosInstance from "Api";
import { RiContactsBookUploadFill } from "react-icons/ri";

const List = () => {

  const [data, setData] = useState([]);
  const [accordion, setAccordion] = useState([]);

  const fetchData = async () => {
    try {
      return await DocumentService.getDocumentList()
    } catch (error) {}
  };
  useEffect(() => {
    if (data.length === 0) {
      fetchData().then((response) => {
      if (response.data.documents && response.data.documents.length > 0) {
          console.log("data checker =>", response.data.documents);
          setData(response.data.documents);
          let accordians = Array.from({length: response.data.documents.length}, (_, i) => false);
          setAccordion(accordians);
        }
      });
    }
  }, [data, accordion]);

  function toggleAccordion(tab) {
    let accordians = Array.from({length: data.length}, (_, i) => false);
    accordians[tab] = true;
    setAccordion(accordians);
  }

  return (
      <TransitionGroup>
        <CSSTransition
          component="div"
          classNames="TabsAnimation"
          appear={true}
          timeout={1500}
          enter={false}
          exit={false}
        >
          <Row>
            <Col md="12">
              <div id="accordion" className="accordion-wrapper mb-3">
              {data.length !== 0 ? 
                (data.map((substitute, index) => (
                <Card key={index}>
                  <CardHeader id={"heading"+index} className="substitutor-card-header">
                    <Button
                      block
                      color="link"
                      className="text-start m-0 p-0"
                      onClick={() => toggleAccordion(index)}
                      aria-expanded={accordion[index]}
                      aria-controls={"collapse"+index}
                    >
                      <h5 className="menu-header-title text-capitalize document-list-header">
                        {substitute['admin_name']} - {substitute['vcity']}
                      </h5>
                    </Button>
                  </CardHeader>
                  <Collapse
                    isOpen={accordion[index]}
                    data-parent="#accordion"
                    id={"collapse"+index}
                    aria-labelledby={"heading"+index}
                  >
                    <CardBody>
                        <Record data={substitute['docs']}/>
                    </CardBody>
                  </Collapse>
                </Card>
                )))
              : (<div class="text-center">Geen document beschikbaar</div>)}
              </div>
            </Col>
          </Row>
        </CSSTransition>
      </TransitionGroup>
    );
  }
export default List;
