import Tippy from "@tippyjs/react";
import { useNavigate } from "react-router";
import { Table } from "reactstrap";
import UserInfoPopup from "./UserInfoPopup";
import { parse } from "../../utils";

const WeekReportInfo = ({data}) => {
    // data.vAbsentName - is a string with 3 values
    // perse() splits the string and return object with correct key and values
    let extraData = parse("&nbsp;&nbsp;&nbsp;", data.vAbsentName, ["vAbsentName", "vSalarisId", "dBirthdate"]);

    // put extracted data into general data object
    // replace() returns only numeric value in case if original one is "(SalarisID: 16161)"
    data = {...data, ...{...extraData, vSalarisId: extraData["vSalarisId"]?.replace(/[^0-9]/g, '')}}

    let navigate = useNavigate();

    const onHover = (data) => (<Tippy content={data} placement="bottom"><span> {data}</span></Tippy>)

    let columns = [
        {
            title: "Geboortedatum",
            value: data?.dBirthdate && data?.dBirthdate.length !== 0 ? data.dBirthdate : "-"
        },
        {
            title: "Salaris Id",
            value: data?.vSalarisId && data?.vSalarisId.length !== 0 ? data.vSalarisId : "-"
        },
        {
            title: "WTF",
            value: data?.vWtf && data?.vWtf.length !== 0 ? data.vWtf : "-"
        }
    ];

    const showPopup = columns.filter(item => item.value !== "-").length; // show popup if at least one value is not empty

    return (
        <Table size="sm" borderless className="mb-0 border-btm-none">
        <tbody>
            <tr>
                <th className="fw-bold">Verzoek ID</th>
                <th className="fw-bold">Code</th>
                <th className="fw-bold">Afwezige</th>
                <th className="fw-bold">Verlof- / verzuimmelding</th>
            </tr>
            <tr>
                <td onClick={() => {navigate('/verzoeken/' + data.iSubstitutionId)}} className="wl">
                    {onHover(data.iSubstitutionId)}
                </td>

                <td className="overflow-ellipsis wl">
                    {onHover(data.code)}
                </td>

                <td className="wl">
                    {showPopup
                        ? <UserInfoPopup name={data.vAbsentName} columns={columns}/>
                        : data.vAbsentName
                    }
                </td>

                <td className="wl">
                    {onHover(data.iPlanOption)}
                </td>
            </tr>
            <tr>
                <th className="fw-bold">Memo t.b.v. weekstaat</th>
                <th className="fw-bold">&nbsp;</th>
                <th className="fw-bold">1e / laatste dag</th>
                <th className="fw-bold">Kostenplaats / project</th>
            </tr>
            <tr>
                <td onClick={() => {navigate('/verzoeken/' + data.iSubstitutionId)}} className="overflow-ellipsis wl">
                    {onHover(data.vMemoWeekstaat)}
                </td>

                <td className="wl">&nbsp;</td>

                <td className="wl">
                    {onHover(data.first_day + ' / ' + data.last_day)}
                </td>

                <td className="wl">
                    {onHover(data.vKostenplaats)}
                </td>
            </tr>
        </tbody>
    </Table>
    )
};

export default WeekReportInfo;
