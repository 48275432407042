export const PartsOfDay = Object.freeze({
  MondayMorning: "maandag_ochtend",
  MondayAfternoon: "maandag_middag",
  TuesdayMorning: "dinsdag_ochtend",
  TuesdayAfternoon: "dinsdag_middag",
  WednesdayMorning: "woensdag_ochtend",
  WednesdayAfternoon: "woensdag_middag",
  ThursdayMorning: "donderdag_ochtend",
  ThursdayAfternoon: "donderdag_middag",
  FridayMorning: "vrijdag_ochtend",
  FridayAfternoon: "vrijdag_middag",
});