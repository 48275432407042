import React, {useState} from 'react';
import {ErrorMessage, Field, Form, Formik} from "formik";

import * as Yup from "yup";
import FormStatus from "Pages/Profile/Forms/FormStatus";
import { Col, Container, FormGroup, Label, Row} from "reactstrap";
import axiosInstance from "Api";
import Auth from "services/Auth";
import DefaultButton from 'Components/Buttons/DefaultButton';

function ProfileUsernameForm(props) {
    const [message, setMessage] = useState({type: null, text: ''});
    const [displayUsername, setDisplayUsername] = useState(props.username);
    const validationSchema = Yup.object().shape({
        vUserName: Yup.string()
            .required("Gebruikersnaam mag niet leeg zijn").max(50, 'Moet 50 tekens of minder zijn')
            .matches(/^[a-z]+$/, "Gebruikersnaam moet geen cijfers en speciale tekens bevatten !@#$%^&*()_+")
    });

    return (
        <Formik initialValues={{vUserName: ''}}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting, setFieldError , resetForm}) => {
                    axiosInstance.patch('users/' + props.id, values).then(response => {
                            if (response.data.status === "success") {
                                Auth.setAuthenticatedUser(response.data.user);
                                setMessage({type: 'success', text: 'Gebruikersnaam succesvol gewijzigd'})
                                setDisplayUsername(response.data.user.vUserName);
                                resetForm();
                            }else{
                                console.log("Ekese");
                                console.log(response.data.data.vUserName[0]);
                                setFieldError ('vUserName', response.data.data.vUserName[0]);
                            }
                        }
                    ).catch(err => {
                        
                        if (err.response) {
                            console.log( err );
                            if (err.response.status === 422) {
                                let errors = {};
                                for (let key in err.response.data.errors) {
                                    errors[key] = err.response.data.errors[key][0];
                                }
                            }
                        }
                    }).finally(() => {
                            setSubmitting(false);
                        }
                    );
                }}
        >
            {(formik) => (
                <Form>
                    <Container>
                        {message.type && <FormStatus message={message} status="success" changeMessage={setMessage}/>}
                        <Row>
                            <Col sm="6">
                                <Row className="pb-3">
                                    <Col sm="6">
                                        Huidige gebruikersnaam:
                                    </Col>
                                    <Col sm="6">
                                        {displayUsername}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Label for="password_current">
                                                Kies een nieuwe gebruikersnaam:
                                            </Label>
                                        </FormGroup>
                                        {/* <InputWrapper label="Kies een nieuwe gebruikersnaam:" labelCol={6}>
                                        </InputWrapper> */}
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup>
                                            <Field 
                                                className={formik.errors.vUserName && formik.touched.vUserName ? "form-control is-invalid" : "form-control"} 
                                                name="vUserName"
                                            />
                                            <ErrorMessage name="vUserName" component="small"
                                                  className="block mt-2 invalid-feedback"/>
                                        </FormGroup>
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Col sm={{size: 6, offset: 6}}>
                                        <DefaultButton type="submit" disabled={formik.isValid ? false : true}
                                                className="mb-2 me-0 btn-color-style" color="primary">
                                            Gebruikersnaam aanpassen
                                        </DefaultButton>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Form>)}
        </Formik>
    );
}

export default ProfileUsernameForm;
