import React, {Fragment, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import axiosInstance from "Api";
import {
    Card,
    CardBody,
    Col,
    Row,
} from "reactstrap";
import WeekReportDetails from "Pages/Melder/WeekReport/Show/Partials/WeekReportDetails";
import PageHeading from "Layout/AppMain/PageHeading";
import WeekReportTables from "./WeekReportTables";
import WeekReportForm from "./WeekReportForm";
import {useLocation} from "react-router";
import {CSSTransition, TransitionGroup} from 'react-transition-group';


const Default = (props) => {
    const [weekReportData, setWeekReportData] = useState(null);
    const [weekReportAccept, setWeekReportAccept] = useState();
    const params = useParams();
    let { state } = useLocation();

    useEffect(() => {
        if (!weekReportData) {
            const substitutionId = params.id || '';
            axiosInstance.get('weekstaat/' + state.wid + '?wsid=' + state.wsId + '&schoolId='+state.schoolId).then((response) => {
                if (response.status == 200) {
                    setWeekReportData(response.data.data);
                }
            }).catch((error) => console.log("catch error:", error));
        }
    }, [weekReportData]);

    const columns = [
        {
            header: () => 'Vervanger',
            accessorKey: 'vSubstName',
        },
        {
            header: () => 'Salaris ID',
            accessorKey: 'vSalarisId',
        },
        {
            header: () => 'Begindatum',
            accessorKey: 'dDateStart',
        },
        {
            header: () => 'Einddatum',
            accessorKey: 'dDateEnd',
        },
        {
            header: () => 'Wtf Ma',
            accessorKey: 'iWtfMa',
        },
        {
            header: () => 'Wtf Di',
            accessorKey: 'iWtfDi',
        },
        {
            header: () => 'Wtf Wo',
            accessorKey: 'iWtfWo',
        },
        {
            header: () => 'Wtf Do',
            accessorKey: 'iWtfDo',
        },
        {
            header: () => 'Wtf Vr',
            accessorKey: 'iWtfVr',
        },
        {
            header: () => 'Total',
            accessorKey: 'iWtfSum',
        },
        {
            header: () => 'Salaris',
            accessorKey: 'vFake',
            cell: ({value}) => (
                <input className="mx-3" checked={value} type="checkbox"/>
            )
        },
    ];


    return (
    <TransitionGroup>
        <CSSTransition component="div" classNames="TabsAnimation" appear={true}
                        timeout={1500} enter={false} exit={false}>
            <div className="app-inner-layout" >
                    <PageHeading />
                <div className="app-inner-layout__wrapper">
                    <Card className="app-inner-layout__content">
                        <Row>
                            <Col md="12">
                                <WeekReportDetails reportData={weekReportData} />
                            </Col>
                        </Row>
                        {weekReportAccept && 
                            <Row>
                                <Col>
                                    <Card className="mb-3 text-danger">
                                        <CardBody>
                                            <div>{weekReportAccept}</div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col md="12">
                                {weekReportData && weekReportData.data.length === 0 &&
                                <CardBody className="p-3">
                                    <p className="alert alert-secondary">Geen verzoeken met dagdelen en/of langdurige verzoeken gevonden binnen de gewenste periode.</p>
                                </CardBody>
                                }
                                {weekReportData && weekReportData.data.map((data, id) => (
                                    <WeekReportTables key={id} data={data} columns={columns} />
                                ))}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {weekReportData &&
                                    <WeekReportForm status={weekReportData.status} message={weekReportData.note} />
                                }
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        </CSSTransition>
    </TransitionGroup>
    )
}


export default Default;
