import React from 'react'

import {
  Card,
  CardBody,
  Col,
  Row
} from "reactstrap";

import PageHeading from "Layout/AppMain/PageHeading";

export default function AppInnerCard({children, headingProps, wrapperClassName = "", contentClassName = "", mainCardClassName = "", cardBodyClassname = ""}) {
  return (
    <div className="app-inner-layout app-inner-card">
      <PageHeading
        {...headingProps}
      />
      <div className={`app-inner-layout__wrapper app-inner-card__wrapper ${wrapperClassName}`}>
        <Card className={`app-inner-layout__content app-inner-card__content ${contentClassName}`}>
          <div>
            <Row>
                <Col md="12" lg="12">
                  <Card className={`main-card mb-3 app-inner-card__main-card ${mainCardClassName}`}>
                    <CardBody className={`p-3 app-inner-card__card-body ${cardBodyClassname}`}>
                      {children}
                    </CardBody>
                  </Card>
                </Col>
            </Row>
          </div>
        </Card>
      </div>
    </div>
  )
}
