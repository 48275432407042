import React, { useState, useEffect } from 'react';
import { Button, Col, Container, FormGroup, Label, Row } from "reactstrap";
import axiosInstance from "Api";
import { Card, CardBody } from "reactstrap";

const GeneralSettingsForm = () => {
    const [generalSettings, setGeneralSettings] = useState();

    useEffect(() => {
        axiosInstance.get('general-settings').then(response => {
            if (response.status === 200) {
                setGeneralSettings(response.data.data);
            }
        }).catch(e => console.log(e))
    }, [])

    if (generalSettings) {
        return (
            <Container className="general-setting-page-style">
                <Row>
                    <Col sm="6">
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        Enable Email :
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        {generalSettings.enableEmail !== '' ? generalSettings.enableEmail : '--'}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password">
                                        Enable SMS :
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password">
                                        {generalSettings.enableSms !== '' ? generalSettings.enableSms : '--'}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        Redirect Email :
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        {generalSettings.redirectionEmail !== '' ? generalSettings.redirectionEmail : '--'}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        Redirect SMS :
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col sm="6">
                                <FormGroup>
                                    <Label for="password_current">
                                        {generalSettings.redirectionSms !== '' ? generalSettings.redirectionSms : '--'}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        return (
            <CardBody>
                <Container>
                    <Row>
                        <Col sm="12">
                            <div class="text-center">bezig met laden</div>
                        </Col>
                    </Row>
                </Container>
            </CardBody>
        )
    }
}

export default GeneralSettingsForm;
