import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import moment from "moment";
import Tippy from "@tippyjs/react";

import {HiOutlineDotsCircleHorizontal} from "react-icons/hi";
import {HiOutlineQuestionMarkCircle} from "react-icons/hi";
import {BsCheckCircle} from "react-icons/bs";
import {TiDeleteOutline} from "react-icons/ti";
import {TiFolderOpen} from "react-icons/ti";
import UserInfoPopup from "./UserInfoPopup";



export const iconSwitch = (param) => {
    switch(param) {
        case 'Openstaand':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineDotsCircleHorizontal fontSize={"18px"} /></span></Tippy>;
        case 'Aangevraagd':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><HiOutlineQuestionMarkCircle color="#ffa73f" fontSize={"18px"} /></span></Tippy>;
        case 'Ingepland':
            return <Tippy content={param} placement="bottom"><span><BsCheckCircle color='#39A825' fontSize={"14px"}/></span></Tippy>;
        case 'Geannuleerd':
            return <Tippy content={param} placement="bottom"><span style={{margin: -4}}><TiDeleteOutline color='#FB2E5F' fontSize={"20px"}/></span></Tippy>;
        case 'Historie':
            return <Tippy content={param} placement="bottom"><span className="weekstaat-icon-align"><TiFolderOpen fontSize={"18px"}/></span></Tippy>;
        default:
            return '--';
    }
}
const WeekReportTable = ({reportData}) => {
    const [totalWtf, setTotalWtf] = useState(0);

    // example: "0,1053" => 0.1053
    const toNumber = str => {
        if (typeof str !== 'undefined') {
            return parseFloat(str.replace(",", "."));
        } return 0;
    };

    useEffect(() => {
        setTotalWtf(
            Math.round(
                reportData
                .map(e => toNumber(e.data.O?.vWtfValue) + toNumber(e.data.M?.vWtfValue))
                .reduce((x, y) => x + y) * 10000) / 10000
        );
    }, [reportData]);

   

    const columns = (data) => [
        
        {
            title: "Geboortedatum",
            value: data?.dBirthdate !== undefined && data?.dBirthdate !== null && data?.dBirthdate.length !== 0 ? data.dBirthdate : "--"
        },
        {
            title: "Salaris Id",
            value: data?.vSalarisId !== null && data?.vSalarisId !== undefined  && data?.vSalarisId.length !== 0 ? data.vSalarisId : "--"
        }
    ];

    if (!reportData) return null;
    return (
        <Table hover size="sm" className="mb-0">
            <thead>
            <tr>
                <th className="text-lef w-32" colSpan="2">&nbsp;</th>
                <th colSpan="6" className="">Ochtend</th>
                <th colSpan="5" className="">Middag</th>
            </tr>
            <tr>
                <th className="text-left" colSpan="2">Datum</th>

                <th className="text-left">Vervanger</th>
                <th>WTF</th>
                <th>CT</th>
                <th>DT</th>
                <th className="text-right">Status</th>
                <th>&nbsp;</th>
                <th className="text-left">Vervanger</th>
                <th>WTF</th>
                <th>CT</th>
                <th>DT</th>
                <th className="text-right">Status</th>
            </tr>
            </thead>
            <tbody>
            {reportData.map((block, i) => {
                    return (
                        <tr key={i}>
                            <td  className="text-capitalize" width="35px">
                                {new Date(moment(block.day, 'DD-MM-YYYY')).toLocaleDateString("nl-NL", { weekday: 'short' })}
                            </td>
                            <td className="table-right-border cursor-pointer" width="120px">{block.day}</td>
                            {
                                block.data.O ?
                                    <>
                                        <td>
                                            <div className="overflow-ellipsis ws">
                                                <UserInfoPopup name={block.data.O.vAbsentName} columns={columns(block.data.O)}/>
                                            </div>
                                        </td>
                                        <td>{block.data.O.iClooserId > 0 ? block.data.O.vWtf + " " + block.data.O.vWtfValue : "--"} </td>
                                        <td>{block.data.O.iClooserId > 0 ? block.data.O.ct : "--"} </td>
                                        <td>{block.data.O.iClooserId > 0 ? block.data.O.dt : "--"} </td>
                                        <td>{block.data.O.iClooserId > 0 ? iconSwitch(block.data.O.vStatus) : "--"} </td>
                                    </>
                                    :
                                    <td colSpan="5" className="text-center">&nbsp;</td>
                            }
                            <td className="table-right-border">&nbsp;</td>
                            {
                                block.data.M ?
                                    <>
                                        <td>
                                            <div className="overflow-ellipsis ws">
                                                <UserInfoPopup name={block.data.M.vAbsentName} columns={columns(block.data.O)}/>
                                            </div>
                                           {/*  <div className="overflow-ellipsis ws">
                                                <UserInfoPopup data={columns(block.data.M)}/>
                                            </div> */}
                                        </td>
                                        <td>{block.data.M.iClooserId > 0 ? block.data.M.vWtf + " " + block.data.M.vWtfValue : "--"} </td>
                                        <td>{block.data.M.iClooserId > 0 ? block.data.M.ct : "--"} </td>
                                        <td>{block.data.M.iClooserId > 0 ? block.data.M.dt : "--"} </td>
                                        <td>{block.data.M.iClooserId > 0 ? iconSwitch(block.data.M.vStatus) : "--"} </td>
                                    </>
                                    :
                                    <td colSpan="5" className="text-center">&nbsp;</td>
                            }
                        </tr>
                    )
                })
            }
            <tr>
                <td colSpan="14"><span className="me-3 weekstaat__total">Total</span> {totalWtf}</td>
            </tr>
            </tbody>
        </Table>
    );
};

export default WeekReportTable;
