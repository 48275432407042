import React, { Component, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { useDispatch, useSelector } from "react-redux";
import { setFeedbackCount, setWeekstateCount } from "reducers/MenuCount";
import { setHomeItemCount } from "reducers/VervangerMenuCount";
import { useEffect } from "react";
import CommonService from "Api/Common";
import Layout from "./SideBar";

const Nav=()=> {

  const dispatch = useDispatch();
  dispatch(setEnableMobileMenu())
  const enableMobileMenu = useSelector((state) => state.ThemeOptions.enableMobileMenu);

  const fetchData = async () => {
    try {
      return await CommonService.getMelderCounts()
    } catch (error) { }
  };

  const fetchSubstitutorCount = async () => {
    try {
      return await CommonService.getSubstitutorCounts()
    } catch (error) { }
  };

  useEffect(() => {

    let role = localStorage.getItem('USER_ROLES');
    if (role.includes('substitutor')) {
      fetchSubstitutorCount().then((response) => {
        if (response.data.countsArray.homeItemCount >= 0) {
          dispatch(setHomeItemCount(response.data.countsArray.homeItemCount));
        }
      });
    }
   
    if (role.includes('requestor')) {
      fetchData().then((response) => {
        if (response.data.countsArray.feedbackCount >= 0) {
          dispatch(setFeedbackCount(response.data.countsArray.feedbackCount));
          dispatch(setWeekstateCount(response.data.countsArray.weekReportActualCount));
        }
      });
    }
    
  },[]);


    return (
      <Fragment>
          <Layout/>
      </Fragment>
    );
}



export default Nav;
