import { Fragment } from "react";

// Components
import Default from "./Partials";

const Show = ({match}) => {
    return (
        <Fragment>
            <Default/>
        </Fragment>
    )
};

export default Show;