import React, { Fragment } from "react";

import Maintenance from "../../Components/Maintenance";
class AppFooter extends React.Component {

  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <Maintenance/>
            <div className="app-footer-right">
              mijn.clooser.nl
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;
