import React, { useEffect, useRef, useState } from "react";
import { Button, Card } from "reactstrap";
import FileUploadService from "../../../services/FileUploadService";
import FormStatus from "Pages/Profile/Forms/FormStatus";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfilePicture } from "reducers/UserProfile";
import ProfilePictureCrop from "Components/Modal/ProfilePictureCrop/index";

const ImageUploadForm = ({ user }) => {
  const dispatch = useDispatch();
  const userProfileImage = useSelector((state) => state.UserProfile.profileImage);
  const [file, setFile] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [image, setImage] = useState(userProfileImage);

  const [message, setMessage] = useState({ type: null, text: "" });
  const inputFile = useRef(null);
  const fileSize = 2;

  //redux dispatcher
  useEffect(() => {
    dispatchProfilePictureAction();
  }, [image]);

  const dispatchProfilePictureAction = () => {
    dispatch(setUserProfilePicture(image));
  }

  const toggleProfileCropPopup = val => {
    setOpenModel(val);
    if(val === false ){
      setFile("");
    }
  }

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type: mime});
  };

  const handleCroppedImage = (val) => {
    if( val !== ''){
      setImage(val);
      setOpenModel(false);
      uploadProfileImage(dataURLtoFile(val, file.name));
    }
  }

  const changeProfilePicture = (e) => {

    e.preventDefault();
    const file = e.target.files[0];

    if (!/\.(jpe?g|png)$/i.test(file.name)) {
      messageHandler("error", "Alleen jpeg/jpg, png-bestanden toegestaan");
      return;
    }
    if (file.size >= fileSize * 1024 * 1024) {
      messageHandler("error", "Maximale bestandsgrootte is 2 MB");
      return;
    }

    setOpenModel(true);
    setFile(file);
  };

  const onButtonClick = (e) => {
    inputFile.current.click();
  };

  const messageHandler = (type, text) => {
    setMessage({ type, text });
  };

  const uploadProfileImage = (file) => {
    console.log(file);
    FileUploadService.upload("/users/change-profile-picture", file)

      .then((response) => {
        setImage(response.data.content);
        let userData = JSON.parse( localStorage.getItem('user'));
        userData.profileImage = response.data.content;
        localStorage.setItem( 'user', JSON.stringify(userData) )
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            let errors = {};
            for (let key in err.response.data.errors) {
              errors[key] = err.response.data.errors[key][0];
            }
            setMessage({ type: "error", text: errors.file });
          }
        }
      });
  };

  return (
    <Card className="mb-3 profile-block profile-page-main">
      {message.type && (
        <FormStatus
          message={message}
          status="success"
          changeMessage={setMessage}
        />
      )}
      <div>
        <img src={userProfileImage} className="card-img-top" alt="..." />
      </div>
      <div className="dropdown-menu-header">
        <div className="profile-inner bg-dark opacity-6" />
        <div className="menu-header-content">
          <h5 className="menu-header-title">
            {user.vFirstName + " " + user.vLastName}
          </h5>
          <div className="menu-header-btn-pane">
            <input
              name="fileinput"
              ref={inputFile}
              type="file"
              className="d-none"
              onChange={changeProfilePicture}
            />
            <Button
              className="btn-icon btn-pill me-2 btn-icon-only"
              color="link"
              onClick={onButtonClick}
            >
              <i className="lnr-inbox btn-icon-wrapper"> </i>
            </Button>
          </div>
        </div>
      </div>
      <ProfilePictureCrop isOpen={openModel} file={file} togglePopup={toggleProfileCropPopup} croppedFile={handleCroppedImage} classes="feedback-modal-style"/>
    </Card>
    
  );
};

export default ImageUploadForm;
